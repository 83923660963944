import React from "react";
import moment from "moment";

import Button from "../../Admin/Button/Button";
import PlaybackContainer from "../PlaybackContainer";

import "./DateChecker.scss";

const initialPlaybackId = 0;

export default class DateChecker extends React.Component {
  state = {
    interactedWith: false,
    lastDate: null,
    playbackId: initialPlaybackId // just a random id to force the playback component to re-render
  };

  playbackTicks = [];

  componentDidMount() {
    setInterval(this.checkDate, 1000);
  }

  refreshPlayback = () => {
    this.setState({ playbackId: this.state.playbackId + 1 });
  };

  checkDate = () => {
    const { lastDate } = this.state;
    const today = moment().format("YYYY-MM-DD");

    if (lastDate && lastDate !== today) {
      console.log("Detected date change, refreshing playback");
      this.playbackTicks = [];
      setTimeout(this.refreshPlayback, 1000);
    }
    this.setState({
      lastDate: today
    });
  };

  recordPlaybackTick = playbackId => {
    if (playbackId !== this.state.playbackId) {
      // if we have an unclosed setInterval sending us bad ticks, ignore them
      return;
    }
    if (this.playbackTicks.length > 6) {
      this.playbackTicks = this.playbackTicks.slice(
        this.playbackTicks.length - 6,
        this.playbackTicks.length
      );
    }

    this.playbackTicks.push(Date.now());

    if (this.playbackTicks.length > 2) {
      let weHaveProblem = false;
      for (let i = 1; i < this.playbackTicks.length; i++) {
        const prev = this.playbackTicks[i - 1];
        const crt = this.playbackTicks[i];

        if (crt - prev < 500) {
          weHaveProblem = true;
          break;
        }
      }

      if (weHaveProblem) {
        this.playbackTicks = [];
        console.log("PROBLEM with time syncing, refreshing playback");
        console.log(
          "playbackId: ",
          playbackId,
          "current:",
          this.state.playbackId
        );
        setTimeout(this.refreshPlayback, 100);
      }
    }
  };

  render() {
    if (!this.state.interactedWith) {
      return (
        <div className="not-interacted-with-container">
          <Button
            label={<i className="fas fa-play" />}
            type="secondary"
            className="start-playback"
            onClick={() => this.setState({ interactedWith: true })}
          />
        </div>
      );
    }

    return (
      <PlaybackContainer
        id={this.state.playbackId}
        key={this.state.playbackId}
        tick={this.recordPlaybackTick}
        refresh={this.refreshPlayback}
      />
    );
  }
}
