import React, { Component } from "react";

import { getReadableTime } from "../../../utilities";

import title from "../../../assets/nextworkout.png";
import "./NextWorkout.scss";

export default class NextWorkout extends Component {
  render() {
    const { timeLeft } = this.props;

    return (
      <div className="nextworkout-container">
        <div className="nextworkout">
          <img className="title" src={title} alt="" />
          <p>{getReadableTime(timeLeft, false)}</p>
        </div>
      </div>
    );
  }
}
