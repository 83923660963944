import React from "react";
import cx from "classnames";

import "./Video.scss";

export default class Video extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  playVideo = () => {
    if (this.videoRef.current) {
      this.videoRef.current.play();
    }
  };

  pauseVideo = () => {
    if (this.videoRef.current) {
      this.videoRef.current.pause();
    }
  };

  render() {
    const { src, className, autoPlay = true, controls = false } = this.props;

    const classNameCompiled = cx(className, "video");

    let propsVideoVersion = {
      src,
      autoPlay,
      muted: true,
      loop: true,
      controls,
      className: classNameCompiled,

      ref: this.videoRef
    };

    if (!autoPlay) {
      propsVideoVersion.onMouseEnter = this.playVideo;
      propsVideoVersion.onMouseLeave = this.pauseVideo;
    }

    let propsImageVersion = {
      src,
      className: classNameCompiled
    };

    return (
      <>
        {src.includes(".gif") ? (
          <img {...propsImageVersion} alt="" />
        ) : (
          <video {...propsVideoVersion} />
        )}
      </>
    );
  }
}
