import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { post } from "../../api";

import Video from "../../Video/Video";

import "./ExerciseDetails.scss";

const queryExercise = {
  query: `
    query ($id: Int!) {
      exercise_definition(id: $id) {
        id  
        name
        muscles
        difficulty
        video
      }
    }
  `
};

export function ExerciseDetails({
  id,
  data,
  editMode,
  match,
  isGroupExercises
}) {
  const [exercise, setExercise] = useState(data);

  let exerciseId = id;

  if (editMode && match) {
    exerciseId = parseInt(match.params.id);
  }

  function fetchSingleExercise() {
    post(`/api/graphql`, {
      ...queryExercise,
      variables: { id: exerciseId }
    }).then(response => {
      const exercise = response.data.data.exercise_definition;

      setExercise(exercise);
    });
  }

  function displayEquipments() {
    let label = "none";

    if (exercise.equipments.length > 0) {
      label = exercise.equipments.map(equipment => equipment.name).join(", ");
    }

    return <span>{label}</span>;
  }

  function displayType() {
    let label = "none";

    const types = exercise.muscles.filter(
      item => item.muscle_group && item.muscle_group.toLowerCase() === "type"
    );

    if (types.length > 0) {
      label = types.map(type => type.name).join(", ");
    }

    return <span>{label}</span>;
  }

  function displayMuscle() {
    let label = "No muscles assigned";

    const realMuscles = exercise.muscles.filter(
      muscle =>
        muscle.muscle_group &&
        muscle.muscle_group.toLowerCase() !== "cardio" &&
        muscle.muscle_group.toLowerCase() !== "type"
    );

    if (realMuscles.length > 0) {
      label = realMuscles.map(muscle => muscle.name).join(", ");
    }

    return <span>{label}</span>;
  }

  function displayCardio() {
    let label = "none";

    const cardioMuscles = exercise.muscles.filter(
      muscle =>
        muscle.muscle_group && muscle.muscle_group.toLowerCase() === "cardio"
    );

    if (cardioMuscles.length > 0) {
      label = cardioMuscles.map(muscle => muscle.name).join(", ");
    }

    return <span>{label}</span>;
  }

  if (!exercise && !data) {
    fetchSingleExercise();

    return <p>Loading...</p>;
  }

  let orderLabel = 1;

  if (isGroupExercises) {
    orderLabel = exercise.order_index;
  } else {
    orderLabel = exercise.order_index + 1;
  }

  return (
    <div className="exercise-container">
      <label className="order-index">No. {orderLabel}</label>
      <div className="row main-content">
        <div className="info-container">
          <div className="info-item">
            <span className="exercise-name">{exercise.name}</span>
          </div>
          <div className="info-item muscle-list">{displayMuscle()}</div>
          <div className="info-item">
            <label>Difficulty:</label>
            <span>{exercise.difficulty}</span>
          </div>
          <div className="info-item">
            <label>Type:</label>
            <span>{displayType()}</span>
          </div>
          <div className="info-item">
            <label>Equipment:</label>
            {displayEquipments()}
          </div>
          <div className="info-item">
            <label>Cardio:</label>
            {displayCardio()}
          </div>
        </div>
        <div className="image-container">
          <Video src={exercise.video} autoPlay={false} />
        </div>
      </div>
    </div>
  );
}

export default withRouter(ExerciseDetails);
