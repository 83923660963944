import React from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";
import AdminContainer from "./Admin/AdminContainer/AdminContainer";

import DateChecker from "./Playback/DateChecker/DateChecker";

import "./App.scss";

class App extends React.Component {
  render() {
    return (
      <div className="app">
        <Router>
          <Route
            exact
            path="/playback/station/:stationNumber/:date?"
            component={() => <DateChecker />}
          />
          <Route path="/admin" component={() => <AdminContainer />} />
          <Route exact path="/" component={() => <AdminContainer />} />
        </Router>
      </div>
    );
  }
}

export default App;
