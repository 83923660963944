import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { post } from "../../api";
import moment from "moment";

import Button from "../Button/Button";

import "./CircuitList.scss";

const queryCircuits = {
  query: `
    query {
      circuit_definitions {
        id
        name
        created
        station_checks_ok
        time_checks_ok
      }
    }
  `
};

const queryCreateCircuit = {
  query: `
    mutation ($input: CircuitInput!){
      circuit: createCircuit(input: $input) {
        id
      }

      station0: createStation (input: {
        station_number: 0
      }) {
        id
      }
      station1: createStation (input: {
        station_number: 1
      }) {
        id
      }
      station2: createStation (input: {
        station_number: 2
      }) {
        id
      }
      station3: createStation (input: {
        station_number: 3
      }) {
        id
      }
      station4: createStation (input: {
        station_number: 4
      }) {
        id
      }
      station100: createStation (input: {
        station_number: 100,
        checks_ok: true
      }) {
        id
      }
    }
  `
};

const queryAssignStations = {
  query: `
    mutation ($circuitId: Int!, $stationId0: Int!, $stationId1: Int!, $stationId2: Int!, $stationId3: Int!, $stationId4: Int!, $stationId100: Int!) {
      station0: assignStation (circuit_id: $circuitId, station_id: $stationId0) 
      station1: assignStation (circuit_id: $circuitId, station_id: $stationId1) 
      station2: assignStation (circuit_id: $circuitId, station_id: $stationId2) 
      station3: assignStation (circuit_id: $circuitId, station_id: $stationId3) 
      station4: assignStation (circuit_id: $circuitId, station_id: $stationId4) 
      station100: assignStation (circuit_id: $circuitId, station_id: $stationId100) 
    }
  `
};

export function CircuitList({ history }) {
  const [circuits, setCircuits] = useState();
  const [circuitName, setCircuitName] = useState("");
  const [sortField, setSortField] = useState("created");
  const [sortDirection, setSortDirection] = useState(-1);

  function fetchCircuits() {
    post(`/api/graphql`, queryCircuits).then(response => {
      const rawCircuits = response.data.data.circuit_definitions;
      const newCircuits = rawCircuits.map(circuit => {
        return {
          ...circuit,
          valid: circuit.station_checks_ok && circuit.time_checks_ok
        };
      });

      setCircuits(newCircuits);
    });
  }

  function sortBy(fieldName) {
    let newDirection = 1;
    if (sortField === fieldName) {
      newDirection = -sortDirection;
    }

    setSortField(fieldName);
    setSortDirection(newDirection);
  }

  function createCircuit() {
    post(`/api/graphql`, {
      ...queryCreateCircuit,
      variables: { input: { name: circuitName } }
    }).then(response => {
      const {
        circuit,
        station0,
        station1,
        station2,
        station3,
        station4,
        station100
      } = response.data.data;
      post(`/api/graphql`, {
        ...queryAssignStations,
        variables: {
          circuitId: circuit.id,
          stationId0: station0.id,
          stationId1: station1.id,
          stationId2: station2.id,
          stationId3: station3.id,
          stationId4: station4.id,
          stationId100: station100.id
        }
      }).then(response => {
        history.push(`/admin/circuit_definition/${circuit.id}`);
      });
    });
  }

  function displayCircuitList() {
    if (!circuits) {
      return null;
    }

    let sortedCircuits = circuits;
    if (sortDirection && sortField) {
      sortedCircuits = [...circuits].sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return sortDirection;
        } else {
          return -sortDirection;
        }
      });
    }

    return sortedCircuits.map(circuit => {
      let validIcon = circuit.valid ? (
        <i className="fas fa-check" />
      ) : (
        <i className="fas fa-times" />
      );

      return (
        <tr
          key={circuit.id}
          className="circuit-item"
          onClick={() => {
            history.push(`/admin/circuit_definition/${circuit.id}`);
          }}
        >
          <td>
            {validIcon} {circuit.name}
          </td>
          <td>
            {circuit.created &&
              moment(parseInt(circuit.created)).format("DD MMM YYYY")}
          </td>
        </tr>
      );
    });
  }

  if (!circuits) {
    fetchCircuits();
    return <p>Loading...</p>;
  }

  let nameSortIcon = null;
  let createdSortIcon = null;

  if (sortField === "name") {
    if (sortDirection === 1) {
      nameSortIcon = <i className="fas fa-sort-up" />;
    } else {
      nameSortIcon = <i className="fas fa-sort-down" />;
    }
  } else if (sortField === "created") {
    if (sortDirection === 1) {
      createdSortIcon = <i className="fas fa-sort-up" />;
    } else {
      createdSortIcon = <i className="fas fa-sort-down" />;
    }
  }

  return (
    <div className="circuit-list-container">
      <div className="create-circuit-container">
        <h3>Circuit Name</h3>
        <input
          value={circuitName}
          onChange={e => setCircuitName(e.target.value)}
          className="circuit-name"
          placeholder="Type circuit name here "
        />

        <Button
          icon={<i className="fas fa-plus" />}
          label="Create circuit"
          type="primary"
          enabled={circuitName && circuitName.length > 0}
          onClick={createCircuit}
        />
      </div>
      <table className="circuit-list">
        <thead>
          <tr>
            <th onClick={() => sortBy("name")}>{nameSortIcon}Name </th>
            <th onClick={() => sortBy("created")}>
              {createdSortIcon}Created On
            </th>
          </tr>
        </thead>
        <tbody>{displayCircuitList()}</tbody>
      </table>
    </div>
  );
}

export default withRouter(CircuitList);
