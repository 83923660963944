import React, { Component } from "react";
import "./Timer.scss";
import SVGArc from "./SVGArc";

export default class Timer extends Component {
  render() {
    const { timeLeft, currentView } = this.props;
    let angle = 0;
    if (currentView) {
      angle = (timeLeft / currentView.duration) * 359.999;
    }
    const cx = 100;
    const cy = 102;
    let colour = "";

    if (currentView) {
      switch (currentView.type) {
        case "rest":
          colour = "#DBD92F";
          break;
        case "switch":
          colour = "#CD2C31";
          break;
        default:
          colour = "#4fd950";
          break;
      }
    }
    return (
      <div className="timer-container">
        <SVGArc
          strokeWidth={10}
          radius={90}
          startAngle={0}
          endAngle={angle}
          color={"#ffffff"}
          className="outer-circle"
        />
        <svg
          className="innercircle-svg"
          width="250"
          height="200"
          viewBox="0 0 250 200"
        >
          <circle
            cx={cx}
            cy={cy}
            r="78"
            stroke="none"
            strokeWidth="none"
            fill={colour}
          ></circle>
        </svg>
        <span className="time">{timeLeft}</span>
      </div>
    );
  }
}
