import React, { useState, useEffect } from "react";

import { post } from "../../api";

import { MUSCLE_GROUPS } from "../constants";

import "./ExerciseFilters.scss";

const queryMuscles = {
  query: `
    query {
      muscle_definitions {
        id
        name
        muscle_group
      }
    }
  `
};

const queryEquipments = {
  query: `
    query {
      equipment_definitions {
        id
        name
      }
    }
  `
};

const queryExercise = {
  query: `
    query ($id: Int!) {
      exercise_definition(id: $id) {
        id  
        name
        difficulty
        video
        muscles {
          id
          definition_id
        }
        equipments {
          id
          definition_id
        }
      }
    }
  `
};

export default function ExerciseFilters({ onChange, exerciseId, chooseMode }) {
  const [muscles, setMuscles] = useState();
  const [equipments, setEquipments] = useState();
  const [exercise, setExercise] = useState();

  const initialDifficulties = [
    { id: 1, name: 1, checked: false },
    { id: 2, name: 2, checked: false },
    { id: 3, name: 3, checked: false }
  ];

  if (exerciseId === undefined && chooseMode) {
    initialDifficulties.push({
      id: "any",
      name: "Any difficulty",
      checked: false
    });
  }

  const [difficulties, setDifficulties] = useState(initialDifficulties);

  useEffect(() => {
    if (muscles && equipments && difficulties) {
      if (!exercise && exerciseId !== undefined) {
        fetchExercise();
      }
    }
  }, [exercise, exerciseId, muscles, equipments, difficulties]);

  useEffect(() => {
    onChange({ muscles, equipments, difficulties });
  }, [muscles, equipments, difficulties]);

  useEffect(() => {
    if (!muscles) {
      fetchMuscles();
    }
  }, [muscles]);

  useEffect(() => {
    if (!equipments) {
      fetchEquipments();
    }
  }, [equipments]);

  function fetchExercise() {
    post(`/api/graphql`, {
      ...queryExercise,
      variables: { id: exerciseId }
    }).then(response => {
      const exercise = response.data.data.exercise_definition;
      setExerciseAndPreCheckedFilters(exercise);
    });
  }

  function setExerciseAndPreCheckedFilters(exercise) {
    setExercise(exercise);

    // muscles
    const newMuscles = muscles.map(muscle => {
      const exerciseHasMuscle = exercise.muscles.some(
        exerciseMuscle => exerciseMuscle.definition_id === muscle.id
      );
      return { ...muscle, checked: exerciseHasMuscle };
    });

    // equipments
    const newEquipments = equipments.map(equipment => {
      const exerciseHasEquipment = exercise.equipments.some(
        exerciseEquipment => exerciseEquipment.definition_id === equipment.id
      );
      return { ...equipment, checked: exerciseHasEquipment };
    });

    // difficulties
    const newDifficulties = difficulties.map(difficulty => {
      const exerciseIsOfDifficulty = exercise.difficulty === difficulty.id;
      return { ...difficulty, checked: exerciseIsOfDifficulty };
    });

    setMuscles(newMuscles);
    setEquipments(newEquipments);
    setDifficulties(newDifficulties);
  }

  function fetchMuscles() {
    post(`/api/graphql`, queryMuscles).then(response => {
      const items = response.data.data.muscle_definitions;
      items.forEach(item => (item.checked = false));
      setMuscles(items);
    });
  }

  function fetchEquipments() {
    post(`/api/graphql`, queryEquipments).then(response => {
      const items = response.data.data.equipment_definitions;
      items.forEach(item => (item.checked = false));
      setEquipments(items);
    });
  }

  function displayMuscles({ isType = false }) {
    if (!muscles) {
      return null;
    }

    let targetGroups = [];
    if (isType) {
      targetGroups = MUSCLE_GROUPS.filter(
        group => group.toLowerCase() === "type"
      );
    } else {
      targetGroups = MUSCLE_GROUPS.filter(
        group => group.toLowerCase() !== "type"
      );
    }

    return targetGroups.map(muscleGroup => {
      const muscleElements = muscles
        .filter(muscle => {
          const isInCorrectGroup = muscle.muscle_group === muscleGroup;
          let shouldShow = true;
          if (muscle.name.toLowerCase().includes("any")) {
            shouldShow = chooseMode;
          }
          return isInCorrectGroup && shouldShow;
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(muscle => (
          <div className="input-group" key={`muscle-${muscle.id}`}>
            <input
              type="checkbox"
              id={`muscle-${muscle.id}`}
              checked={muscle.checked}
              onChange={e => {
                setMuscles(
                  muscles.map(crtMuscle => ({
                    ...crtMuscle,
                    checked:
                      crtMuscle.id === muscle.id
                        ? e.target.checked
                        : crtMuscle.checked
                  }))
                );
              }}
            />
            <label htmlFor={`muscle-${muscle.id}`}> {muscle.name}</label>
          </div>
        ));

      return (
        <div
          className="column"
          key={`muscle-group-${muscleGroup.split(" ").join("_")}`}
        >
          <p className="group-name">{muscleGroup}</p>
          <div className="muscles">{muscleElements}</div>
        </div>
      );
    });
  }

  function displayDifficulties() {
    if (!difficulties) {
      return null;
    }

    return difficulties.map(difficulty => (
      <div className="input-group" key={`difficulty-${difficulty.id}`}>
        <input
          type="radio"
          name="exercise-difficulty"
          id={`difficulty-${difficulty.id}`}
          checked={difficulty.checked}
          onChange={() => {
            setDifficulties(
              difficulties.map(crtDifficulty => ({
                ...crtDifficulty,
                checked: crtDifficulty.id === difficulty.id
              }))
            );
          }}
        />
        <label htmlFor={`difficulty-${difficulty.id}`}>{difficulty.name}</label>
        <br></br>
      </div>
    ));
  }

  function displayEquipments() {
    if (!equipments) {
      return null;
    }

    return [...equipments]
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map(equipment => (
        <div className="input-group" key={`equipment-${equipment.id}`}>
          <input
            type="checkbox"
            value="Bike"
            id={`equipment-${equipment.id}`}
            checked={equipment.checked}
            onChange={e => {
              setEquipments(
                equipments.map(crtEquipment => ({
                  ...crtEquipment,
                  checked:
                    crtEquipment.id === equipment.id
                      ? e.target.checked
                      : crtEquipment.checked
                }))
              );
              onChange({ muscles, equipments, difficulties });
            }}
          />
          <label htmlFor={`equipment-${equipment.id}`}> {equipment.name}</label>
          <br></br>
        </div>
      ));
  }

  return (
    <div className="exercise-filters">
      <div className="muscles-container">
        <h3>Muscles</h3>
        <div className="row">{displayMuscles({ isType: false })}</div>
      </div>
      <div className="other-filters-container row">
        <div className="type-container">{displayMuscles({ isType: true })}</div>
        <div className="equipment-container column">
          <h3>Equipment</h3>
          <div className="">{displayEquipments()}</div>
        </div>
        <div className="difficulty-container column">
          <h3>Difficulty</h3>
          <div className="">{displayDifficulties()}</div>
        </div>
      </div>
    </div>
  );
}
