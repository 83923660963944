import React from "react";
import "./Version.scss";

const versionNumber = 30;
const versionDate = "27 Jan";

console.log(`App Version: ${versionNumber}. Released on: ${versionDate}`);

export default function Version() {
  const versionElement = (
    <>
      {versionNumber} <br />({versionDate})
    </>
  );

  return <label className="app-version">V {versionElement}</label>;
}
