import React, { useEffect, useState } from "react";
import { post } from "../../api";

import { Link } from "react-router-dom";

import { MUSCLE_GROUPS } from "../constants";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";

import "./ItemList.scss";

const queryEquipments = {
  query: `
    query {
      equipment_definitions {
        created
        id
        name
        used_in {
          id
          name
        }
      }
    }
  `
};

const queryMuscles = {
  query: `
    query {
      muscle_definitions {
        created
        id
        name
        muscle_group
        used_in {
          id
          name
        }
      }
    }
  `
};

const queryCreateItem = {
  query: `
    mutation ($input: ItemInput!){
      createItem(input: $input) {
        id
      }
    }
  `
};

const queryDeleteItem = {
  query: `
    mutation ($id: Int!){
      deleteItem_definition(id: $id)
    }
  `
};

const queryUpdateItem = {
  query: `
    mutation ($id: Int!, $input: ItemInput!){
      updateItem_definition(id: $id, input: $input) {
        id
      }
    }
  `
};

export default function ItemList({ item, itemCapital }) {
  const [items, setItems] = useState();
  const [itemName, setItemName] = useState("");
  const [itemUnderEdit, setItemUnderEdit] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [
    shouldShowEditConfirmationModal,
    setShouldShowEditConfirmationModal
  ] = useState(false);
  const [muscleGroup, setMuscleGroup] = useState(MUSCLE_GROUPS[0]);

  useEffect(() => {
    if (!items) {
      fetchItems();
    }
  }, [items]);

  function getRealQuery(queryObj) {
    return {
      ...queryObj,
      query: queryObj.query
        .split("Item")
        .join(itemCapital)
        .split("item")
        .join(item)
    };
  }

  function fetchItems() {
    let query = item === "muscle" ? queryMuscles : queryEquipments;
    post(`/api/graphql`, query).then(response => {
      setItems(response.data.data[`${item}_definitions`]);
    });
  }

  function createItem() {
    const variables = {
      input: { name: itemName }
    };

    if (item === "muscle") {
      variables.input.muscle_group = muscleGroup;
    }

    post(`/api/graphql`, {
      ...getRealQuery(queryCreateItem),
      variables
    }).then(() => {
      fetchItems();
    });
  }

  function deleteItem() {
    const variables = {
      id: itemUnderEdit.id
    };
    post(`/api/graphql`, {
      ...getRealQuery(queryDeleteItem),
      variables
    }).then(() => {
      fetchItems();
    });
  }

  function updateItemUnderEdit() {
    const { id, name } = itemUnderEdit;
    setItemUnderEdit();
    const variables = {
      id,
      input: {
        name
      }
    };
    post(`/api/graphql`, {
      ...getRealQuery(queryUpdateItem),
      variables
    }).then(() => {
      fetchItems();
    });
  }

  function handleEditClick(item) {
    setItemUnderEdit(item);

    if (item.used_in.length > 0) {
      setShouldShowEditConfirmationModal(true);
    } else {
      setIsEditMode(true);
    }
  }

  function endEditMode() {
    setIsEditMode(false);
    updateItemUnderEdit();
  }

  function displayEditConfirmationModal() {
    if (!shouldShowEditConfirmationModal) {
      return null;
    }

    let content = null;
    if (itemUnderEdit.used_in.length > 0) {
      let title = "";
      if (isDeleteMode) {
        title = `This ${item} is already in use in the following exercises, so it cannot be deleted:`;
      } else {
        title = `You are about to edit an ${item} in use in the following exercises:`;
      }

      content = (
        <>
          <h3 className="title">{title}</h3>
          <div className="use-message">
            <ul>
              {itemUnderEdit.used_in.map(exercise => (
                <li key={exercise.id}>
                  <Link to={`/admin/exercise_definition/${exercise.id}`}>
                    {exercise.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </>
      );
    } else {
      content = (
        <>
          <h3>
            Are you sure you want to {isDeleteMode ? "delete" : "edit"} this
            item?
          </h3>
          <div className="use-message not-used">
            This item is not used in any exercises
          </div>
        </>
      );
    }

    return (
      <Modal
        className="edit-confirmation-modal"
        onClose={() => {
          setIsDeleteMode(false);
          setShouldShowEditConfirmationModal(false);
        }}
      >
        {content}
        <div className="button-container">
          <Button
            label="Cancel"
            icon={<i className="fas fa-times" />}
            type="secondary"
            onClick={() => setShouldShowEditConfirmationModal(false)}
          />
          {isDeleteMode && itemUnderEdit.used_in.length > 0 ? null : (
            <Button
              label="Continue"
              icon={
                <i className={isDeleteMode ? "fas fa-trash" : "fas fa-check"} />
              }
              type="primary"
              onClick={() => {
                setShouldShowEditConfirmationModal(false);
                if (isDeleteMode) {
                  setIsDeleteMode(false);
                  deleteItem();
                } else {
                  setIsEditMode(true);
                }
              }}
            />
          )}
        </div>
      </Modal>
    );
  }

  function displayNewItem() {
    return (
      <div className="new-item-container">
        <h3>Add a new {item}</h3>
        <div className="input-group">
          <label>Name</label>
          <input value={itemName} onChange={e => setItemName(e.target.value)} />
          {item === "muscle" ? (
            <div className="input-group">
              <label>Muscle Group</label>
              <select
                value={muscleGroup}
                onChange={e => setMuscleGroup(e.target.value)}
              >
                {MUSCLE_GROUPS.map(group => (
                  <option value={group} key={group}>
                    {group}{" "}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
        </div>

        <Button
          icon={<i className="fas fa-plus" />}
          label={`Create ${item}`}
          type="primary"
          onClick={createItem}
        />
      </div>
    );
  }

  function displayItems() {
    if (!items) {
      return null;
    }

    let sortedItems = null;
    if (item === "muscle") {
      sortedItems = [...items].sort((a, b) => {
        if (a.muscle_group > b.muscle_group) {
          return 1;
        } else if (a.muscle_group < b.muscle_group) {
          return -1;
        } else {
          return a.name > b.name ? 1 : -1;
        }
      });
    } else {
      sortedItems = [...items].sort((a, b) => (a.name > b.name ? 1 : -1));
    }
    return sortedItems.map(currentItem => {
      let label = null;
      let editOrSaveButton = null;

      if (isEditMode && itemUnderEdit.id === currentItem.id) {
        label = (
          <input
            className="name"
            value={itemUnderEdit.name}
            onChange={e => {
              setItemUnderEdit({
                ...itemUnderEdit,
                name: e.target.value
              });
            }}
            onKeyUp={e => {
              if (e.key === "Enter") {
                endEditMode();
              }
            }}
          />
        );
        editOrSaveButton = <i className="fas fa-save" onClick={endEditMode} />;
      } else {
        label = (
          <span className="name">
            {item === "muscle" ? `${currentItem.muscle_group} - ` : ""}
            {currentItem.name}
          </span>
        );
        editOrSaveButton = (
          <i
            className="fas fa-edit"
            onClick={() => handleEditClick(currentItem)}
          />
        );
      }

      return (
        <li key={currentItem.name}>
          {editOrSaveButton}
          {label}
          <i
            className="fas fa-trash"
            onClick={() => {
              setItemUnderEdit(currentItem);
              setShouldShowEditConfirmationModal(true);
              setIsDeleteMode(true);
            }}
          />
        </li>
      );
    });
  }

  if (!items) {
    return <p>Loading...</p>;
  }

  return (
    <div className="item-list-container">
      <h2>{itemCapital}s</h2>
      {displayNewItem()}

      <ul className="item-list">{displayItems()}</ul>
      {displayEditConfirmationModal()}
    </div>
  );
}
