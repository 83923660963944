import React from "react";
import { Route } from "react-router-dom";

import "./AdminContainer.scss";

import Sidebar from "../Sidebar/Sidebar";
import CalendarPage from "../CalendarPage/CalendarPage.jsx";
import CircuitDetails from "../CircuitDetails/CircuitDetails.jsx";
import CircuitList from "../CircuitList/CircuitList.jsx";
import ExerciseList from "../ExerciseList/ExerciseList.jsx";
import ExerciseEditor from "../ExerciseEditor/ExerciseEditor.jsx";
import ItemList from "../ItemList/ItemList.jsx";
import AdminPlayback from "../AdminPlayback/AdminPlayback.jsx";
import Version from "../Version/Version";

export default function AdminContainer() {
  return (
    <div className="admin-container">
      <Version />
      <div className="sidebar-outer-container">
        <Sidebar />
      </div>
      <div className="content-outer-container">
        <Route path="/admin" exact component={() => <CalendarPage />} />
        <Route path="/" exact component={() => <CalendarPage />} />
        <Route
          path="/admin/circuit_definition/:id/:stationId?"
          exact
          component={() => <CircuitDetails editMode={true} />}
        />

        <Route
          path="/admin/circuit_definitions"
          exact
          component={() => <CircuitList />}
        />

        <Route
          path="/admin/exercise_definition/:id"
          exact
          component={() => <ExerciseEditor editMode={true} />}
        />
        <Route
          path="/admin/exercise_definitions"
          exact
          component={() => <ExerciseList />}
        />
        <Route
          path="/admin/new_exercise"
          exact
          component={() => <ExerciseEditor newMode={true} />}
        />
        <Route
          path="/admin/equipment_definitions"
          exact
          component={() => (
            <ItemList item="equipment" itemCapital="Equipment" />
          )}
        />
        <Route
          path="/admin/muscle_definitions"
          exact
          component={() => <ItemList item="muscle" itemCapital="Muscle" />}
        />
        <Route
          path="/admin/playback"
          exact
          component={() => <AdminPlayback />}
        />
      </div>
    </div>
  );
}
