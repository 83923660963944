import React, { Component } from "react";

import Video from "../../Video/Video";

import "./GroupExercise.scss";
import Timer from "../Timer/Timer";
export default class GroupExercise extends Component {
  displayCurrentVideo = () => {
    const { exercise } = this.props;

    return (
      <div className="exercise-item">
        <Video src={exercise.video} />
      </div>
    );
  };

  render() {
    const { timeLeft, currentView } = this.props;

    return (
      <div className={"group-exercise-container"}>
        <div className="center-row">
          <div className="timer-outside-container left">
            <Timer timeLeft={timeLeft} currentView={currentView} />
          </div>
          {this.displayCurrentVideo()}
          <div className="timer-outside-container right">
            <Timer timeLeft={timeLeft} currentView={currentView} />
          </div>
        </div>
      </div>
    );
  }
}
