import React, { useState, useEffect, useDebugValue } from "react";
import { withRouter } from "react-router-dom";
import { post } from "../../api";

import "./StationDetails.scss";

import ExerciseDetails from "../ExerciseDetails/ExerciseDetails";
import Modal from "../Modal/Modal";
import ExerciseList from "../ExerciseList/ExerciseList";
import Button from "../Button/Button.jsx";

import {
  WARMUP_TARGET_EXERCISES,
  GROUP_TARGET_EXERCISES,
  STATION_TARGET_EXERCISES
} from "../constants.js";

const queryStation = {
  query: `
    query ($id: Int!) {
      station_definition(id: $id) {
        id
        station_number
        
        exercises {
          id  
          video
          name
          difficulty
          muscles {
            name
            muscle_group
          }
          equipments {
            name
          }
          video
          order_index
        }
      }
    }
  `
};

const queryUpdateStation = {
  query: `
    mutation ($id: Int!, $input: StationInput!) {
      updateStation_definition(id: $id, input: $input) {
        id
      }
    }
  `
};

const queryUpdateCircuit = {
  query: `
    mutation ($id: Int!, $input: CircuitInput!) {
      updateCircuit_definition(id: $id, input: $input) {
        id
      }
    }
  `
};

const queryUpdateExercise = {
  query: `
    mutation ($id: Int!, $input: ExerciseInput!) {
      updateExercise(id: $id, input: $input) {
        id
      }
    }
  `
};

const queryAssignExercise = {
  query: `
    mutation ($stationId: Int!, $exerciseId: Int!, $orderIndex: Int!) {
      assignExercise(station_id: $stationId, exercise_id: $exerciseId, order_index: $orderIndex)
    }
  `
};

const queryDeleteExercise = {
  query: `
    mutation ($id: Int!) {
      deleteExerciseAssignment(id: $id)
    }`
};

export function StationDetails({
  id,
  editMode,
  isGroupExercises,
  onCheckStatusChange,
  match
}) {
  const [station, setStation] = useState();
  const [originalStation, setOriginalStation] = useState();
  const [showNewExerciseModal, setShowNewExerciseModal] = useState(false);
  const [pendingSlotIndex, setPendingSlotIndex] = useState(); // this is for the group exercise feature
  const [updateState, setUpdateState] = useState(); // "state" means the state of the "update" (or "save") process

  // these properties deal with the "drag and drop" functionality
  const [isDraggingIndex, setIsDraggingIndex] = useState();
  const [dropHoverIndex, setDropHoverIndex] = useState();
  const [dragItemIndex, setDragItemIndex] = useState();

  const [exerciseMinCount, setExerciseMinCount] = useState();
  const [exerciseMaxCount, setExerciseMaxCount] = useState();

  useDebugValue(station);

  useEffect(() => {
    setStation();
    fetchSingleStation();
  }, [id]);

  useEffect(() => {
    if (
      !station ||
      exerciseMinCount === undefined ||
      exerciseMaxCount === undefined
    ) {
      return;
    }

    const isOK = isStationValid(station, exerciseMinCount, exerciseMaxCount);
    if (isOK !== station.checks_ok) {
      setStation({
        ...station,
        checks_ok: isOK
      });
    }
  }, [station, exerciseMinCount, exerciseMaxCount]);

  function computeMinAndMaxExerciseCount(stationData) {
    let min;
    let max;
    switch (stationData.station_number) {
      case 0:
        max = WARMUP_TARGET_EXERCISES;
        min = WARMUP_TARGET_EXERCISES;
        break;
      case 100:
        max = GROUP_TARGET_EXERCISES;
        min = 0;
        break;
      default:
        max = STATION_TARGET_EXERCISES;
        min = STATION_TARGET_EXERCISES;
    }
    return { min, max };
  }

  function isStationValid(stationData, min, max) {
    if (!stationData) {
      return;
    }

    const notDeletedExerciseCount = stationData.exercises.filter(
      exercise => !exercise.deleted
    ).length;

    let isValid = true;
    if (stationData.station_number === 0 && notDeletedExerciseCount === 0) {
      isValid = true;
    } else {
      if (notDeletedExerciseCount < min) {
        isValid = false;
      }
      if (notDeletedExerciseCount > max) {
        isValid = false;
      }
    }
    return isValid;
  }

  function fetchSingleStation() {
    post(`/api/graphql`, { ...queryStation, variables: { id } }).then(
      response => {
        const station = response.data.data.station_definition;
        let { min, max } = computeMinAndMaxExerciseCount(station);
        let checksOK = isStationValid(station, min, max);

        setStation({ ...station, checks_ok: checksOK });
        setExerciseMinCount(min);
        setExerciseMaxCount(max);

        const originalStation = {
          ...station,
          checks_ok: checksOK
        };

        setOriginalStation(originalStation);
      }
    );
  }

  async function updateStation() {
    setUpdateState("pending");
    const newExercises = station.exercises.filter(exercise => exercise.new);
    const deletedExercises = station.exercises.filter(
      exercise => exercise.deleted
    );
    const exercisesToUpdate = station.exercises.filter(
      exercise => !exercise.new && !exercise.deleted
    );

    const promisesAssignments = newExercises.map(pendingExercise => {
      return post(`/api/graphql`, {
        ...queryAssignExercise,
        variables: {
          stationId: id,
          exerciseId: pendingExercise.definition_id,
          orderIndex: pendingExercise.order_index
        }
      }).catch(e => {
        alert(`There has been an error:`, e);
      });
    });

    const promisesDeletions = deletedExercises.map(pendingExercise => {
      return post(`/api/graphql`, {
        ...queryDeleteExercise,
        variables: { id: pendingExercise.id }
      }).catch(e => {
        alert(`There has been an error:`, e);
      });
    });

    const promisesUpdates = exercisesToUpdate.map(exercise => {
      return post(`/api/graphql`, {
        ...queryUpdateExercise,
        variables: {
          id: exercise.id,
          input: { order_index: exercise.order_index }
        }
      }).catch(e => {
        alert(`There has been an error:`, e);
      });
    });

    await Promise.all([
      ...promisesAssignments,
      ...promisesDeletions,
      ...promisesUpdates
    ]);

    const stationForVariables = { ...station };
    delete stationForVariables.exercises;
    delete stationForVariables.id;
    const variablesStation = {
      id,
      input: stationForVariables
    };

    post(`/api/graphql`, {
      ...queryUpdateStation,
      variables: variablesStation
    })
      .then(() => {
        // setOriginalStation(...station);
        onCheckStatusChange(station.checks_ok);
        setTimeout(() => {
          setUpdateState("success");
        }, 500);

        setTimeout(() => {
          setUpdateState();
        }, 2500);
        fetchSingleStation();
      })
      .catch(e => {
        setTimeout(() => {
          setUpdateState("error");

          alert(`There has been an error:`, e);
        }, 500);

        setTimeout(() => {
          setUpdateState();
        }, 2500);
        fetchSingleStation();
      });
  }

  function assignExercise(newExercise) {
    newExercise.id = `new_` + Math.floor(Math.random() * 100000);
    newExercise.new = true;

    let newExerciseIndex;

    if (!isGroupExercises) {
      let lastMaxOrderIndex = -1;
      station.exercises.forEach(exercise => {
        if (exercise.order_index > lastMaxOrderIndex && !exercise.deleted) {
          lastMaxOrderIndex = exercise.order_index;
        }
      });

      newExerciseIndex = lastMaxOrderIndex + 1;
    } else {
      newExerciseIndex = pendingSlotIndex;
      // if we've choosen an exercise, there is no pending slot anymore (only applicable to group exercises)
      setPendingSlotIndex();
    }

    newExercise = {
      ...newExercise,
      order_index: newExerciseIndex
    };

    setStation({
      ...station,
      exercises: [...station.exercises, newExercise]
    });
    setShowNewExerciseModal(false);
  }

  function deleteExercise(exerciseToDelete) {
    let newExercises = [...station.exercises];

    // if the exercise being targeted had just been added (i.e. not already in the database), delete it for real
    if (exerciseToDelete.new) {
      newExercises = newExercises.filter(exercise =>
        exercise.new && exerciseToDelete.id === exercise.id ? false : true
      );
    } else {
      // find the exercise being targeted and mark it as "deleted"
      const targetedExercise = newExercises.find(
        exercise => exercise.id === exerciseToDelete.id
      );
      targetedExercise.deleted = true;
    }

    if (station.station_number !== 100) {
      newExercises.forEach(exercise => {
        if (exercise.order_index > exerciseToDelete.order_index) {
          exercise.order_index--;
        }
      });
    }

    // add the 'deleted' property to the item and also adjust the ordering to account for the newly-removed item
    setStation({
      ...station,
      exercises: newExercises
    });
  }

  function onExerciseDragStart(e, index, orderIndex) {
    e.dataTransfer.setData("text/plain", orderIndex);
    e.dataTransfer.dropEffect = "move";
    setIsDraggingIndex(index);
    setDragItemIndex(orderIndex);
  }

  function onExerciseDragEnd(e, index) {
    setIsDraggingIndex();
    setDragItemIndex();
  }

  function onExerciseDrop(e, index) {
    e.preventDefault();
    setDragItemIndex();
    const droppedItemIndex = parseInt(e.dataTransfer.getData("text/plain"));
    let dropZoneIndex = index;
    if (droppedItemIndex > dropZoneIndex) {
      dropZoneIndex = index + 1;
    }
    const newExercises = station.exercises.map(exercise => {
      let newOrderIndex;

      if (droppedItemIndex < dropZoneIndex) {
        if (
          exercise.order_index > droppedItemIndex &&
          exercise.order_index <= dropZoneIndex
        ) {
          newOrderIndex = exercise.order_index - 1;
        } else if (exercise.order_index === droppedItemIndex) {
          newOrderIndex = dropZoneIndex;
        } else {
          newOrderIndex = exercise.order_index;
        }
      } else if (droppedItemIndex > dropZoneIndex) {
        if (exercise.order_index < dropZoneIndex) {
          newOrderIndex = exercise.order_index;
        } else if (
          exercise.order_index >= dropZoneIndex &&
          exercise.order_index < droppedItemIndex
        ) {
          newOrderIndex = exercise.order_index + 1;
        } else if (exercise.order_index === droppedItemIndex) {
          newOrderIndex = dropZoneIndex;
        } else {
          newOrderIndex = exercise.order_index;
        }
      } else {
        newOrderIndex = exercise.order_index;
      }

      return {
        ...exercise,
        order_index: newOrderIndex
      };
    });

    setStation({
      ...station,
      exercises: newExercises
    });
    setIsDraggingIndex();
  }

  function displayGroupSlots() {
    return (
      <>
        {displaySlot(1)}
        {displaySlot(2)}
        {displaySlot(3)}
        {displaySlot(4)}
      </>
    );
  }

  function displaySlot(slotNumber) {
    const exerciseInSlot = station.exercises.find(
      exercise => exercise.order_index === slotNumber && !exercise.deleted
    );
    if (exerciseInSlot) {
      return (
        <div className="exercise-outer-container">
          {!editMode ? null : (
            <Button
              className="remove-exercise"
              icon={<i className="fas fa-times" />}
              type="close"
              onClick={() => deleteExercise(exerciseInSlot)}
            />
          )}
          <ExerciseDetails
            id={exerciseInSlot.id}
            data={exerciseInSlot}
            isGroupExercises={isGroupExercises}
          />
        </div>
      );
    } else {
      return displayNewExerciseButton(slotNumber);
    }
  }

  function displayExercises() {
    // TODO: fix sorting (it changes on every render)
    const exerciseElements = station.exercises
      .filter(exercise => !exercise.deleted)
      .sort((a, b) => (a.order_index > b.order_index ? 1 : -1))
      .map((exercise, index) => {
        let containerClassName = "exercise-outer-container";
        if (isDraggingIndex === index) {
          containerClassName += " dragging";

          if (
            dropHoverIndex !== undefined &&
            dropHoverIndex !== exercise.order_index &&
            dropHoverIndex !== exercise.order_index - 1
          ) {
            containerClassName += " shrunk";
          }
        }

        let dropAreaClassName = "drop-area";

        if (isDraggingIndex !== undefined) {
          dropAreaClassName += " visible";
        } else {
          dropAreaClassName += " hidden";
        }

        let placeholderElement = null;

        if (
          dragItemIndex !== undefined &&
          exercise.order_index !== dragItemIndex &&
          exercise.order_index !== dragItemIndex - 1
        ) {
          if (exercise.order_index === dropHoverIndex) {
            placeholderElement = <div className="item-placeholder"></div>;
            dropAreaClassName += " expanded";
          }
        }

        return (
          <React.Fragment key={exercise.id}>
            <div
              key={`exercise-${exercise.id}-${index}`}
              className={containerClassName}
              draggable
              onDragStart={e =>
                onExerciseDragStart(e, index, exercise.order_index)
              }
              onDragEnd={e => onExerciseDragEnd(e, index)}
            >
              {!editMode ? null : (
                <Button
                  className="remove-exercise"
                  icon={<i className="fas fa-times" />}
                  type="close"
                  onClick={() => deleteExercise(exercise)}
                />
              )}

              <ExerciseDetails id={exercise.id} data={exercise} />
            </div>
            <div
              className={dropAreaClassName}
              key={`drop-area-${exercise.id}-${index}`}
              onDrop={e => onExerciseDrop(e, exercise.order_index)}
              onDragOver={e => {
                e.preventDefault();
              }}
              onDragEnter={e => {
                e.preventDefault();
                setDropHoverIndex(exercise.order_index);
              }}
            ></div>
            {placeholderElement}
          </React.Fragment>
        );
      });

    const newExerciseButton = (
      <React.Fragment key="new-exercise-button">
        {displayNewExerciseButton()}
      </React.Fragment>
    );
    return [...exerciseElements, newExerciseButton];
  }

  function displayNewExerciseButton(slotNumber) {
    if (!editMode) {
      return null;
    }

    const notDeletedExerciseCount = station.exercises.filter(
      exercise => !exercise.deleted
    ).length;

    // if we've already reached the maximum number of exercises allowed, don't show the button anymore
    if (notDeletedExerciseCount >= exerciseMaxCount) {
      return null;
    }

    let label = "Add new exercise";
    if (slotNumber !== undefined) {
      label = `Add exercise for slot ${slotNumber}`;
    }

    return (
      <div
        className="new-exercise-container"
        onClick={() => {
          setPendingSlotIndex(slotNumber);
          setShowNewExerciseModal(true);
        }}
      >
        <button>+</button>
        <label>{label}</label>
      </div>
    );
  }

  function displayNewExerciseModal() {
    if (!showNewExerciseModal) {
      return null;
    }

    return (
      <Modal
        onClose={() => {
          setShowNewExerciseModal(false);
          setPendingSlotIndex();
        }}
      >
        <h2 className="exercise-picker-title">Choose an exercise</h2>
        <ExerciseList pickMode={true} onChange={assignExercise} />
      </Modal>
    );
  }

  if (!station || !originalStation) {
    return <p>Loading...</p>;
  }

  let updateStationLabel = "Save station";
  if (updateState === "pending") {
    updateStationLabel = "Saving...";
  } else if (updateState === "success") {
    updateStationLabel = "Station updated!";
  } else if (updateState === "error") {
    updateStationLabel = "Update failed!";
  }

  let canSaveStation = true;
  if (originalStation.checks_ok && !station.checks_ok) {
    canSaveStation = false;
  }

  let titleLabel = `Station ${station.station_number}`;
  if (station.station_number === 0) {
    titleLabel = "Warmup";
  } else if (station.station_number === 100) {
    titleLabel = "Group Exercises";
  }

  const exercisesInStationCount = station.exercises.filter(
    exercise => !exercise.deleted
  ).length;

  return (
    <div className="station-container">
      <div className="station-details">
        <div className="station-details-inner-container">
          {editMode ? (
            <Button
              label={updateStationLabel}
              icon={<i className="fas fa-save" />}
              type="primary"
              className="save"
              enabled={canSaveStation}
              onClick={updateStation}
            />
          ) : null}
          <h1 className="station-name">{titleLabel}</h1>
          <h3>Minimum exercise number: {exerciseMinCount}</h3>
          <h3>Maximum exercise number: {exerciseMaxCount}</h3>
          <h3>Current exercise number: {exercisesInStationCount}</h3>
        </div>
      </div>

      <div className="exercises-container">
        {station.station_number === 100
          ? displayGroupSlots()
          : displayExercises()}

        {displayNewExerciseModal()}
      </div>
    </div>
  );
}

export default withRouter(StationDetails);
