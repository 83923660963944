import React, { Component } from "react";
import "./ProgressBar.scss";

export default class ProgressBar extends Component {
  displayProgressBar = () => {
    const { currentView, timeLeft, exercises } = this.props;

    let progressArray = exercises.map((exercise, i) => {
      const currentIndex = i;

      let percentage;
      let className;
      let specialDotClassName = "";

      if (currentIndex === currentView.currentExerciseIndex) {
        percentage = Math.abs(
          ((timeLeft - currentView.duration) / currentView.duration) * 100
        );
        className = "full-dot";
      } else if (currentIndex > currentView.currentExerciseIndex) {
        percentage = 0;
        className = "";
      } else {
        percentage = 100;
        className = "full-dot";
      }

      if (currentView.type === "switch") {
        percentage = 100;
        className = "full-dot";
        specialDotClassName = "full-dot";
      }

      return (
        <React.Fragment key={`progress-${i}`}>
          <div className={`dot ${className}`}></div>
          <div className="progress">
            <div
              className="bar"
              style={{
                width: percentage + "%",
                backgroundColor: "#1BA0F6"
              }}
            ></div>
          </div>
          {i === exercises.length - 1 ? (
            <div className={`dot ${specialDotClassName}`}></div>
          ) : null}
        </React.Fragment>
      );
    });

    return progressArray;
  };

  render() {
    return (
      <div className="progressbar-container">
        <div className="progressbar">{this.displayProgressBar()}</div>
      </div>
    );
  }
}
