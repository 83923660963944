import React, { useState } from "react";

import "./AdminPlayback.scss";
import moment from "moment";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

export default function AdminPlayback() {
  const [dateValue, setDateValue] = useState(moment().format("YYYY-MM-DD"));
  return (
    <div className="admin-playback">
      <div className="rolling-date-container">
        <h3>Rolling date playback</h3>
        <div className="stations-container">
          <Link to="/playback/station/0">
            <Button label="Warmup" type="secondary" />
          </Link>
          <Link to="/playback/station/1">
            <Button label="Station 1" type="secondary" />
          </Link>
          <Link to="/playback/station/2">
            <Button label="Station 2" type="secondary" />
          </Link>

          <Link to="/playback/station/3">
            <Button label="Station 3" type="secondary" />
          </Link>

          <Link to="/playback/station/4">
            <Button label="Station 4" type="secondary" />
          </Link>
        </div>
      </div>

      <hr />

      <h3>Set-date playback</h3>

      <div className="date-container">
        <label>Choose a date</label>
        <input
          className="date"
          type="date"
          value={dateValue}
          onChange={e => setDateValue(e.target.value)}
        />
      </div>
      <div className="stations-container">
        <Link to={`/playback/station/0/${dateValue}`}>
          <Button label={`Warmup on ${dateValue}`} type="secondary" />
        </Link>
        <br />
        <br />
        <Link to={`/playback/station/1/${dateValue}`}>
          <Button label={`Station 1 on ${dateValue}`} type="secondary" />
        </Link>
        <br />
        <br />
        <Link to={`/playback/station/2/${dateValue}`}>
          <Button label={`Station 2 on ${dateValue}`} type="secondary" />
        </Link>
        <br />
        <br />

        <Link to={`/playback/station/3/${dateValue}`}>
          <Button label={`Station 3 on ${dateValue}`} type="secondary" />
        </Link>
        <br />
        <br />

        <Link to={`/playback/station/4/${dateValue}`}>
          <Button label={`Station 4 on ${dateValue}`} type="secondary" />
        </Link>
      </div>
    </div>
  );
}
