import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { post } from "../../api";
import moment from "moment";

import { MUSCLE_GROUPS } from "../constants";

import ExerciseFilters from "../ExerciseFilters/ExerciseFilters";
import Button from "../Button/Button";
import Video from "../../Video/Video";

import "./ExerciseList.scss";

const queryExercises = {
  query: `
    query {
      exercise_definitions {
        created
        id
        name
        video
        difficulty

        muscles {
          id
          definition_id
          name
          muscle_group
        }

        equipments {
          definition_id
          name
          id
        }
      }
    }
  `
};

export function ExerciseList({ history, pickMode, onChange }) {
  const [exercises, setExercises] = useState();
  const [filteredExercises, setFilteredExercises] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState();
  const [weHaveFilters, setWeHaveFilters] = useState(false);

  useEffect(() => {
    if (!exercises) {
      fetchExercises();
    }
  }, [exercises]);

  function fetchExercises() {
    post(`/api/graphql`, queryExercises).then(response => {
      setExercises(response.data.data.exercise_definitions);
      setFilteredExercises(response.data.data.exercise_definitions);
    });
  }

  function computeWeHaveFilters(filters, searchValue) {
    let atLeastOneFilterIsChecked = false;
    if (searchValue && searchValue.length > 0) {
      atLeastOneFilterIsChecked = true;
    } else {
      for (let filterCategoryName in filters) {
        let categoryHasFilterActive = filters[filterCategoryName].some(
          filter => {
            if (filter.checked) {
              atLeastOneFilterIsChecked = true;
              return true;
            }
            return false;
          }
        );
        if (categoryHasFilterActive) {
          break;
        }
      }
    }
    setWeHaveFilters(atLeastOneFilterIsChecked);
  }

  function updateFilteredExerciseList(filters, searchValueParam) {
    const { muscles, equipments, difficulties } = filters;

    setFilters(filters);

    if (!muscles || !equipments || !difficulties) {
      return null;
    }

    // search value
    // if we don't have it as a parameter, take it from the state
    let searchValueToUse = "";
    if (searchValueParam !== undefined && searchValueParam !== null) {
      searchValueToUse = searchValueParam.toLowerCase();
    } else {
      searchValueToUse = searchValue.toLowerCase();
    }

    computeWeHaveFilters(filters, searchValueToUse);

    const newFilteredList = exercises.filter(exercise => {
      // muscles
      const groupsWithAnyFilter = {};
      MUSCLE_GROUPS.forEach(muscleGroup => {
        const isOK = muscles.some(
          muscle =>
            muscle.checked &&
            muscle.muscle_group === muscleGroup &&
            muscle.name.toLowerCase().includes("any")
        );
        if (isOK) {
          groupsWithAnyFilter[muscleGroup] = true;
        }
      });

      for (let i = 0; i < muscles.length; i++) {
        if (muscles[i].checked) {
          const groupHasAnyFilter = groupsWithAnyFilter.hasOwnProperty(
            muscles[i].muscle_group
          );
          if (groupHasAnyFilter) {
            const exerciseHasMuscleInGroup = exercise.muscles.some(
              exerciseMuscle => {
                return exerciseMuscle.muscle_group === muscles[i].muscle_group;
              }
            );

            if (!exerciseHasMuscleInGroup) {
              return false;
            }
          } else {
            const exerciseHasMuscle = exercise.muscles.some(exerciseMuscle => {
              return exerciseMuscle.definition_id === muscles[i].id;
            });
            if (!exerciseHasMuscle) {
              return false;
            }
          }
        }
      }

      // equipments
      for (let i = 0; i < equipments.length; i++) {
        if (equipments[i].checked) {
          const exerciseHasEquipment = exercise.equipments.some(
            exerciseEquipments => {
              return exerciseEquipments.definition_id === equipments[i].id;
            }
          );
          if (!exerciseHasEquipment) {
            return false;
          }
        }
      }

      // difficulties
      for (let i = 0; i < difficulties.length; i++) {
        if (difficulties[i].checked && difficulties[i].id !== "any") {
          const exerciseIsOfDifficulty =
            exercise.difficulty === difficulties[i].id;
          if (!exerciseIsOfDifficulty) {
            return false;
          }
        }
      }

      if (!exercise.name.toLowerCase().includes(searchValueToUse)) {
        return false;
      }

      return true;
    });

    setFilteredExercises(newFilteredList);
  }

  function updateSearchValue(e) {
    setSearchValue(e.target.value);

    updateFilteredExerciseList(filters, e.target.value);
  }

  function displaySearchBar() {
    return (
      <div className="search-bar">
        <i className="fas fa-search" />
        <input
          placeholder="Search by exercise name"
          value={searchValue}
          onChange={updateSearchValue}
        />
      </div>
    );
  }

  function displayExerciseList() {
    if (!exercises) {
      return null;
    }

    let weHaveResults = true;

    if (!filteredExercises || filteredExercises.length === 0) {
      weHaveResults = false;
    }

    const sortedExerciseList = [...(filteredExercises || [])].sort((a, b) =>
      parseInt(a.created) < parseInt(b.created) ? 1 : -1
    );

    let rowElements = null;
    if (weHaveFilters) {
      rowElements = sortedExerciseList.slice(0, 50).map(exercise => {
        return (
          <tr
            key={`${exercise.name}-${exercise.id}`}
            className="exercise-item"
            onClick={() => {
              if (pickMode) {
                const exerciseToSend = { ...exercise };
                exerciseToSend.definition_id = exerciseToSend.id;
                delete exerciseToSend.id;

                onChange(exerciseToSend);
              } else {
                history.push(`/admin/exercise_definition/${exercise.id}`);
              }
            }}
          >
            <td className="exercise-name">
              {exercise.name || "-- Exercise has no name --"}
            </td>
            <td>
              <Video
                src={exercise.video}
                autoPlay={false}
                className="video-thumbnail"
              />
            </td>
            <td className="exercise-date">
              {exercise.created &&
                moment(parseInt(exercise.created)).format("DD MMM YYYY")}
            </td>
          </tr>
        );
      });
    } else {
      rowElements = [
        <tr key="no-filters">
          <td colSpan={3} className="no-filters-message">
            Add a filter to see results
          </td>
        </tr>
      ];
    }

    return (
      <div className="exercise-list">
        {displaySearchBar()}

        {!weHaveResults ? (
          <h3 className="no-data">
            No exercises match all the search criteria
          </h3>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Exercise Name</th>
                <th>Thumbnail</th>
                <th style={{ width: "120px" }}>Created On</th>
              </tr>
            </thead>
            <tbody>{rowElements}</tbody>
          </table>
        )}
      </div>
    );
  }

  if (!exercises) {
    return <p className="exercise-list-preloader">Loading...</p>;
  }

  return (
    <div className="exercise-list-container">
      <Link to="/admin/new_exercise">
        {pickMode ? null : (
          <Button
            icon={<i className="fas fa-plus" />}
            label="Create exercise"
            type="secondary"
          />
        )}
      </Link>
      <div className="row">
        <ExerciseFilters
          onChange={updateFilteredExerciseList}
          chooseMode={true}
        />
        {displayExerciseList()}
      </div>
    </div>
  );
}

export default withRouter(ExerciseList);
