import { getReadableTime } from "../utilities";

const STATION_COUNT = 4;

export function buildTimeline({ circuit, stationData, stationNumber }) {
  const singleIterationTimeline = createSingleIteration({
    circuit,
    stationData,
    stationNumber
  });

  const timeline = createCompleteTimeline({
    circuit,
    stationNumber,
    singleIterationTimeline
  });
  return timeline;
}

export function createSingleIteration({ circuit, stationData, stationNumber }) {
  const exerciseCount = stationData.exercises.length;
  const { totalRounds, workoutTime, restTime, switchTime } = getKeyTimes(
    circuit,
    stationNumber
  );

  const timeline = [];

  const totalExercisesInStation = totalRounds * exerciseCount;

  const maxExerciseIndex = stationData.exercises.reduce(
    (max, currentExercise) =>
      currentExercise.order_index > max ? currentExercise.order_index : max,
    0
  );

  for (let round = 0; round < totalRounds; round++) {
    for (
      let exerciseIndex = 0;
      exerciseIndex < exerciseCount;
      exerciseIndex++
    ) {
      const exerciseIndexInStation = round * exerciseCount + exerciseIndex + 1;
      const roundLabel = `${exerciseIndexInStation}/${totalExercisesInStation}`;

      timeline.push({
        round: roundLabel,
        duration: workoutTime,
        type: "video",
        currentExerciseIndex: exerciseIndex
      });
      if (
        round < totalRounds - 1 ||
        (exerciseIndex < exerciseCount - 1 && restTime > 0)
      ) {
        timeline.push({
          round: roundLabel,
          duration: restTime,
          type: "rest",
          currentExerciseIndex: exerciseIndex
        });
      }
    }
  }
  timeline.push({
    round: `0/${totalExercisesInStation}`,
    duration: switchTime,
    type: "switch",
    currentExerciseIndex: maxExerciseIndex
  });

  // console.log("timeline = ");
  // console.log(JSON.stringify(timeline));

  return timeline;
}

export function getGroupExercises(circuit) {
  return circuit.stations
    .find(station => station.station_number === 100)
    .exercises.sort((a, b) => (a.order_index > b.order_index ? 1 : -1));
}

export function createCompleteTimeline({
  circuit,
  stationNumber,
  singleIterationTimeline
}) {
  const { groupExerciseTime } = getKeyTimes(circuit, stationNumber);

  const workoutDuration = circuit.end_time - circuit.start_time;

  const stationIterations = workoutDuration / 600;

  const groupExercises = getGroupExercises(circuit);

  let unfoldedTimeline = [];

  for (let i = 0; i < stationIterations; i++) {
    const copiedTimeline = JSON.parse(JSON.stringify(singleIterationTimeline));

    let stationIteration = [...copiedTimeline];

    if (stationNumber === 0) {
      addEmptyWarmupStep({ stationIteration, position: "beginning" });
    } else {
      // we don't want to show group exercises during warmup
      addGroupExercise({
        i,
        groupExercises,
        stationIteration,
        groupExerciseTime
      });
    }

    unfoldedTimeline.push(...stationIteration);
  }

  for (let i = 0; i < unfoldedTimeline.length; i++) {
    if (i === 0) {
      unfoldedTimeline[i].start = 0;
    } else {
      unfoldedTimeline[i].start =
        unfoldedTimeline[i - 1].start + unfoldedTimeline[i - 1].duration;
    }

    const realTimeStart = circuit.start_time + unfoldedTimeline[i].start;
    const realTimeEnd = realTimeStart + unfoldedTimeline[i].duration;
    unfoldedTimeline[i].startReadable = getReadableTime(realTimeStart);
    unfoldedTimeline[i].endReadable = getReadableTime(realTimeEnd);
  }

  // console.log("unfoldedTimeline = ");
  // console.log(JSON.stringify(unfoldedTimeline));

  return unfoldedTimeline;
}

function addGroupExercise({
  i,
  groupExercises,
  stationIteration,
  groupExerciseTime
}) {
  const groupExerciseSlotIndex = i % STATION_COUNT;
  const groupExerciseForSlot = groupExercises.find(
    exercise => groupExerciseSlotIndex === exercise.order_index - 1
  );

  if (groupExerciseForSlot) {
    const lastIndexInIteration = stationIteration.length - 1;
    stationIteration[lastIndexInIteration].duration -= groupExerciseTime;
    const groupExerciseDetails = {
      duration: groupExerciseTime,
      type: "group",
      exerciseIndex: groupExerciseForSlot.order_index
    };
    stationIteration[lastIndexInIteration - 1].beforeGroupExercise = true;
    stationIteration.splice(lastIndexInIteration, 0, groupExerciseDetails);
  }
}

function addEmptyWarmupStep({ stationIteration, position = "end" }) {
  const emptyStepDetails = {
    duration: 300,
    type: "empty_warmup"
  };
  if (position === "end") {
    stationIteration.push(emptyStepDetails);
  } else {
    stationIteration.unshift(emptyStepDetails);
  }
}

function getKeyTimes(circuit, stationNumber) {
  let totalRounds;
  let workoutTime;
  let restTime;
  let switchTime;
  let groupExerciseTime;

  if (stationNumber === 0) {
    totalRounds = circuit.warmup_rounds;
    workoutTime = circuit.warmup_workout_time;
    restTime = circuit.warmup_rest_time;
    switchTime = circuit.warmup_switch_time;
  } else {
    totalRounds = circuit.station_rounds;
    workoutTime = circuit.station_workout_time;
    restTime = circuit.station_rest_time;
    switchTime = circuit.station_switch_time;
    groupExerciseTime = circuit.station_group_time || 0;
  }
  return { totalRounds, workoutTime, restTime, switchTime, groupExerciseTime };
}

export async function prefetchVideos(stationData) {
  console.log("Prefetching videos");
  const videoRequests = stationData.exercises.map(exercise =>
    prefetchFile(exercise.video)
  );
  const blobUrls = await Promise.all(videoRequests);

  console.log("All videos have been pre-fetched");

  return {
    ...stationData,
    exercises: stationData.exercises.map((exercise, index) => ({
      ...exercise,
      video: blobUrls[index]
    }))
  };
}

export async function prefetchAndPreloadSounds() {
  const regularBeepURL = `${window.S3BucketPath}/sounds/buzzer.wav`;
  const groupExerciseBeepURL = `${window.S3BucketPath}/sounds/group-workout-buzzer.wav`;

  const regularBeepPath = await prefetchFile(regularBeepURL);
  const groupExerciseBeepPath = await prefetchFile(groupExerciseBeepURL);

  window.regularBeep = new Audio(regularBeepPath);
  window.groupExerciseBeep = new Audio(groupExerciseBeepPath);
}

function prefetchFile(url) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";

    xhr.addEventListener(
      "load",
      function() {
        if (xhr.status === 200) {
          var URL = window.URL || window.webkitURL;
          var blob_url = URL.createObjectURL(xhr.response);
          resolve(blob_url);
        } else {
          reject();
        }
      },
      false
    );

    var prev_pc = 0;
    xhr.addEventListener("progress", function(event) {
      if (event.lengthComputable) {
        var pc = Math.round((event.loaded / event.total) * 100);
        if (pc != prev_pc) {
          prev_pc = pc;
          if (pc > 90) {
            // console.log(`${pc}% progress: ${url} `);
          }
        }
      }
    });
    xhr.send();
  });
}
