import React from "react";
import cx from "classnames";

import "./Button.scss";

export default function Button({
  icon,
  label,
  onClick,
  className,
  type,
  enabled = true,
  iconPosition
}) {
  let iconElement = icon ? (
    <span className={cx("icon", iconPosition)}>{icon}</span>
  ) : null;

  let iconAndLabelNormal = (
    <>
      {iconElement}
      {label}
    </>
  );
  let iconAndLabelReversed = (
    <>
      {label}
      {iconElement}
    </>
  );

  return (
    <button
      className={cx(type, className, {
        enabled: enabled,
        disabled: !enabled
      })}
      onClick={onClick}
    >
      {iconPosition === "right" ? iconAndLabelReversed : iconAndLabelNormal}
    </button>
  );
}
