import axios from "axios";

export function post(url, body) {
  return makeRequest(url, body, "post");
}

export function get(url) {
  return makeRequest(url, undefined, "get");
}

export function makeRequest(url, body, method) {
  let headers = {};
  const auth = window.auth;
  if (auth) {
    const userDetails = auth.getSignInUserSession();
    headers = {
      "x-auth-token": userDetails.accessToken.jwtToken
    };
  }
  return axios({
    url,
    method: method.toUpperCase(),
    data: body,
    headers
  }).catch(error => {
    console.log("axios error", error.response.status);
    if (error.response.status === 401) {
      console.log("401 unauthorised, reloading");
      window.location.reload();
    }
  });
}
