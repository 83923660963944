import React, { Component } from "react";
import "./NoCurrentView.scss";
import { getReadableTime, getCurrentSeconds } from "../../utilities";

export default class CurrentView extends Component {
  render() {
    const { currentView, circuit } = this.props;

    if (currentView || !circuit) {
      return null;
    }

    const secondsNow = getCurrentSeconds();

    let currentTimeMessage = `The time is now ${getReadableTime(secondsNow)}`;

    let message = "";

    if (secondsNow < circuit.start_time) {
      const delta = circuit.start_time - secondsNow;
      message = `Workout starts in: ${getReadableTime(delta)}`;
    } else if (secondsNow > circuit.end_time) {
      const workoutEndedMessage = `Workout has ended at ${getReadableTime(
        circuit.end_time
      )}`;
      message = (
        <>
          {currentTimeMessage} <br /> {workoutEndedMessage}
        </>
      );
    }

    return (
      <div className="header-container">
        <h1 className="playback-message">{message}</h1>
      </div>
    );
  }
}
