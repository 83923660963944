import React, { useState } from "react";
import { post } from "../../api";
import moment from "moment";

import Calendar from "../Calendar/Calendar.jsx";
import CircuitPicker from "../CircuitPicker/CircuitPicker.jsx";
import CircuitDetails from "../CircuitDetails/CircuitDetails.jsx";
import Modal from "../Modal/Modal.jsx";
import Button from "../Button/Button.jsx";

import { DUPLICATION_THRESHOLD_DAYS } from "../constants.js";

import { getReadableTime } from "../../utilities";

import "./CalendarPage.scss";

const queryCircuits = {
  query: `
    query {
      circuits {
        id
        definition_id
        start_time
        end_time
        name
        date
      }
    }
  `
};

const queryUpdateCircuit = {
  query: `
    mutation($id: Int!, $input: CircuitInput!) {
      updateCircuit(id: $id, input: $input) {
        id
        start_time
        end_time
        date
      }
    }
  `
};

const queryDeleteCircuit = {
  query: `
    mutation ($id: Int!) {
      deleteCircuitAssignment(id: $id)
    }`
};

const queryAssignCircuit = {
  query: `
    mutation ($circuitId: Int!, $date: String!, $startTime: Int!, $endTime: Int!){
      assignCircuit(circuit_id: $circuitId, date: $date, start_time: $startTime, end_time: $endTime)
    }
  `
};

export default function CalendarPage() {
  const [selectedCircuit, setSelectedCircuit] = useState();
  const [newDay, setNewDay] = useState();
  const [fullDays, setFullDays] = useState();
  const [circuitStartHours, setCircuitStartHours] = useState("");
  const [circuitStartMinutes, setCircuitStartMinutes] = useState("");
  const [circuitEndHours, setCircuitEndHours] = useState("");
  const [circuitEndMinutes, setCircuitEndMinutes] = useState("");

  if (!fullDays) {
    fetchCircuits();
  }

  function fetchCircuits() {
    post(`/api/graphql`, queryCircuits).then(response => {
      const fullDays = getDaysWithCircuits(response.data.data.circuits);
      setFullDays(fullDays);
    });
  }

  function chooseCircuit({ id, startTime, endTime }) {
    const variables = {
      circuitId: id,
      startTime,
      endTime,
      date: newDay
    };

    post(`/api/graphql`, { ...queryAssignCircuit, variables }).then(() => {
      fetchCircuits();
    });
    setNewDay();
  }

  function deleteCircuitAssignment() {
    post(`/api/graphql`, {
      ...queryDeleteCircuit,
      variables: { id: selectedCircuit.id }
    }).then(response => {
      setNewDay();
      setSelectedCircuit();
      fetchCircuits();
    });
  }

  function saveCircuitStartTime() {
    const startTimeInSeconds =
      circuitStartHours * 3600 + circuitStartMinutes * 60;
    const variables = {
      id: selectedCircuit.id,
      input: {
        start_time: startTimeInSeconds
      }
    };
    post(`/api/graphql`, { ...queryUpdateCircuit, variables }).then(() => {
      fetchCircuits();
    });
  }

  function saveCircuitEndTime() {
    const endTimeInSeconds = circuitEndHours * 3600 + circuitEndMinutes * 60;
    const variables = {
      id: selectedCircuit.id,
      input: {
        end_time: endTimeInSeconds
      }
    };
    post(`/api/graphql`, { ...queryUpdateCircuit, variables }).then(() => {
      fetchCircuits();
    });
  }

  function calendarSelectCircuitHandler(circuit) {
    setNewDay();
    setSelectedCircuit(circuit);
    const readableTimeStart = getReadableTime(circuit.start_time);
    const readableTimeEnd = getReadableTime(circuit.end_time);
    setCircuitStartHours(readableTimeStart.split(":")[0]);
    setCircuitStartMinutes(readableTimeStart.split(":")[1]);
    setCircuitEndHours(readableTimeEnd.split(":")[0]);
    setCircuitEndMinutes(readableTimeEnd.split(":")[1]);
  }

  function displaySelectedCircuit() {
    if (!selectedCircuit) {
      return null;
    }

    return (
      <div className="circuit-details-outer-container">
        <CircuitDetails id={selectedCircuit.definition_id} editMode={false} />
      </div>
    );
  }

  function displayCircuitTimes() {
    if (!selectedCircuit) {
      return null;
    }

    return (
      <div className="circuit-time-container">
        {displayCircuitSingleTime("start")}
        {displayCircuitSingleTime("end")}
        <Button
          label="Remove circuit from this date"
          onClick={deleteCircuitAssignment}
          type="primary"
          icon={<i className="fas fa-times" />}
        />
      </div>
    );
  }

  function displayCircuitSingleTime(timeType) {
    let label,
      hoursValue,
      minutesValue,
      hoursOnChange,
      minutesOnChange,
      saveCallback;

    if (timeType === "start") {
      label = "Starts at:";
      hoursValue = circuitStartHours;
      minutesValue = circuitStartMinutes;
      hoursOnChange = setCircuitStartHours;
      minutesOnChange = setCircuitStartMinutes;
      saveCallback = saveCircuitStartTime;
    } else {
      label = "Ends at:";
      hoursValue = circuitEndHours;
      minutesValue = circuitEndMinutes;
      hoursOnChange = setCircuitEndHours;
      minutesOnChange = setCircuitEndMinutes;
      saveCallback = saveCircuitEndTime;
    }

    return (
      <>
        <h3>{label}</h3>
        <div className="time">
          <div className="column">
            <p className="label">Hours</p>
            <input
              className="hours"
              value={hoursValue}
              onChange={e => hoursOnChange(parseInt(e.target.value || "0"))}
            />
          </div>
          <div className="column">
            <p className="label">Minutes</p>
            <input
              className="minutes"
              value={minutesValue}
              onChange={e => minutesOnChange(parseInt(e.target.value || "0"))}
            />
          </div>
        </div>

        <Button
          label={`Update ${timeType} time`}
          onClick={saveCallback}
          type="secondary"
          icon={<i className="fas fa-clock" />}
        />

        <hr />
      </>
    );
  }

  function displayCircuitPicker() {
    if (!newDay) {
      return null;
    }

    return (
      <Modal onClose={() => setNewDay()}>
        <CircuitPicker onSubmit={chooseCircuit} />
      </Modal>
    );
  }

  return (
    <div className="calendar-page">
      <div className="calendar-and-time">
        <Calendar
          fullDays={fullDays}
          onSelectCircuit={calendarSelectCircuitHandler}
          onSelectEmptyDay={id => {
            setSelectedCircuit();
            setNewDay(id);
          }}
          onMonthChange={() => {
            setSelectedCircuit();
            setNewDay();
          }}
        />
        {displayCircuitTimes()}
      </div>
      {displaySelectedCircuit()}

      {displayCircuitPicker()}
    </div>
  );
}

function getDaysWithCircuits(circuits) {
  const secondsInADay = 60 * 60 * 24;
  const duplicatedThreshold = secondsInADay * DUPLICATION_THRESHOLD_DAYS;

  const days = {};

  circuits.forEach((circuit, circuitIndex) => {
    days[circuit.date] = {
      ...circuit
    };
  });

  const sortedCircuits = [...circuits].sort((a, b) => {
    const unixA = moment(a.date).unix();
    const unixB = moment(b.date).unix();
    return unixA > unixB ? 1 : -1;
  });

  for (let i = 0; i < sortedCircuits.length; i++) {
    const circuit = sortedCircuits[i];
    const circuitUnix = moment(circuit.date).unix();
    let duplicateWith;

    for (let j = 0; j < i; j++) {
      const circuitForComparison = sortedCircuits[j];
      const comparisonUnix = moment(circuitForComparison.date).unix();
      const timeDifference = Math.abs(circuitUnix - comparisonUnix);
      if (
        timeDifference < duplicatedThreshold &&
        circuitForComparison.id !== circuit.id &&
        circuitForComparison.definition_id === circuit.definition_id
      ) {
        duplicateWith = circuitForComparison.date;
      }
    }
    days[circuit.date].duplicateWith = duplicateWith;
  }

  return days;
}
