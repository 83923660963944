import React, { Component } from "react";
import cx from "classnames";

import "./Station.scss";

import Video from "../../Video/Video";
import Timer from "../Timer/Timer";
import ViewType from "../ViewType/ViewType";

import letterA from "../../assets/a.png";
import letterB from "../../assets/b.png";
import letterC from "../../assets/c.png";

export default class Station extends Component {
  render() {
    const { timeLeft, station, currentView } = this.props;
    const pathA = station.exercises[0].video;
    const pathB = station.exercises[1].video;
    const pathC = station.exercises[2].video;

    return (
      <div className={"station-playback-container"}>
        {currentView.beforeGroupExercise ? (
          <div className="before-group-exercise-mark" />
        ) : null}
        <div className="top-row">
          <div className="timer-outside-container">
            <Timer timeLeft={timeLeft} currentView={currentView} />
          </div>
          <div className="video-container">
            <Video src={pathA} />
            <img className="letter" src={letterA} alt="A" />
          </div>
          <div className="view-type-outside-container">
            <ViewType currentView={currentView} />
          </div>
        </div>
        <div className="bottom-row">
          <div className="video-container">
            <Video src={pathB} />
            <img className="letter" src={letterB} alt="B" />
          </div>
          <div className="video-container">
            <Video src={pathC} />
            <img className="letter" src={letterC} alt="C" />
          </div>
        </div>
      </div>
    );
  }
}
