import React, { useState } from "react";
import { post } from "../../api";
import Select from "react-select";

import "./CircuitPicker.scss";

import Button from "../Button/Button";

const queryCircuits = {
  query: `
    query {
      circuit_definitions_ready {
        id
        name
      }
    }
  `
};

export default function CircuitPicker({ onSubmit }) {
  const [circuitOptions, setCircuitOptions] = useState();
  const [startTimeHours, setStartTimeHours] = useState(5);
  const [startTimeMinutes, setStartTimeMinutes] = useState(30);
  const [endTimeHours, setEndTimeHours] = useState(22);
  const [endTimeMinutes, setEndTimeMinutes] = useState(0);
  const [selectedOption, setSelectedOption] = useState();

  function submit() {
    onSubmit({
      id: selectedOption.value,
      startTime: startTimeHours * 3600 + startTimeMinutes * 60,
      endTime: endTimeHours * 3600 + endTimeMinutes * 60
    });
  }

  function displayCircuitSingleTime(timeType) {
    let label, hoursValue, minutesValue, hoursOnChange, minutesOnChange;

    if (timeType === "start") {
      label = "Starts at:";
      hoursValue = startTimeHours;
      minutesValue = startTimeMinutes;
      hoursOnChange = setStartTimeHours;
      minutesOnChange = setStartTimeMinutes;
    } else {
      label = "Ends at:";
      hoursValue = endTimeHours;
      minutesValue = endTimeMinutes;
      hoursOnChange = setEndTimeHours;
      minutesOnChange = setEndTimeMinutes;
    }

    return (
      <>
        <h3>{label}</h3>
        <div className="time-container">
          <div className="column">
            <p className="label">Hours</p>
            <input
              className="hours"
              value={hoursValue}
              onChange={e => hoursOnChange(e.target.value)}
            />
          </div>
          <div className="column">
            <p className="label">Minutes</p>
            <input
              className="minutes"
              value={minutesValue}
              onChange={e => minutesOnChange(e.target.value)}
            />
          </div>
        </div>
        <br />
      </>
    );
  }

  if (!circuitOptions) {
    post(`/api/graphql`, queryCircuits).then(response => {
      const circuits = response.data.data.circuit_definitions_ready;
      const options = circuits
        .sort((a, b) => (a.id < b.id ? 1 : -1))
        .map(circuit => ({
          value: circuit.id,
          label: circuit.name
        }));

      setCircuitOptions(options);
    });
    return <p>Loading...</p>;
  }

  return (
    <div className="circuit-picker-container">
      <h3>Select a circuit</h3>
      <div className="dropdown-container">
        <Select
          value={selectedOption}
          options={circuitOptions}
          onChange={setSelectedOption}
        />
      </div>
      {displayCircuitSingleTime("start")}
      {displayCircuitSingleTime("end")}
      <Button
        enabled={selectedOption !== undefined}
        onClick={submit}
        icon={<i className="fas fa-check" />}
        label="Choose"
        type="primary"
      />
    </div>
  );
}
