export function getReadableTime(timeInSeconds, includeHours = true) {
  const hours = Math.floor(timeInSeconds / 3600);

  const minutes = Math.floor((timeInSeconds - hours * 3600) / 60);

  const seconds = timeInSeconds - hours * 3600 - minutes * 60;
  const hoursPadded = String(hours).padStart(2, "0");
  const minutesPadded = String(minutes).padStart(2, "0");
  const secondsPadded = String(seconds).padStart(2, "0");
  if (includeHours) {
    return `${hoursPadded}:${minutesPadded}:${secondsPadded}`;
  } else {
    return `${minutesPadded}:${secondsPadded}`;
  }
}

export function getCurrentSeconds() {
  const now = new Date();
  const secondsNow =
    now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
  return secondsNow;
}
