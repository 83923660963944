import React, { Component } from "react";
import cx from "classnames";

import "./WarmUp.scss";

import Video from "../../Video/Video";
import ProgressBar from "../ProgressBar/ProgressBar";
import Timer from "../Timer/Timer";
import ViewType from "../ViewType/ViewType";
import NextWorkout from "./NextWorkout/NextWorkout";

export default class WarmUp extends Component {
  displayCurrentVideo = () => {
    const { station, currentView } = this.props;

    if (currentView.type === "switch") {
      return null;
    }

    const exercise = station.exercises.find(
      exercise => exercise.order_index === currentView.currentExerciseIndex
    );

    return (
      <div className="exercise-item">
        <Video src={exercise.video} />
      </div>
    );
  };

  displayProgressBar = () => {
    const { currentView, station, timeLeft } = this.props;

    if (currentView && currentView.type !== "switch") {
      return (
        <ProgressBar
          exercises={station.exercises}
          timeLeft={timeLeft}
          currentView={currentView}
        />
      );
    }
  };

  render() {
    const { timeLeft, currentView } = this.props;

    if (currentView && currentView.type === "empty_warmup") {
      return <NextWorkout timeLeft={timeLeft} />;
    }

    return (
      <div
        className={`warmup-container type-${currentView && currentView.type}`}
      >
        <div className="timer-outside-container">
          <Timer timeLeft={timeLeft} currentView={currentView} />
        </div>
        {this.displayCurrentVideo()}
        <div className="view-type-outside-container">
          <ViewType currentView={currentView} />
        </div>
        {this.displayProgressBar()}
      </div>
    );
  }
}
