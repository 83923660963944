import React, { useState, useEffect } from "react";
import "./ExerciseEditor.scss";
import { Link, withRouter } from "react-router-dom";

import ExerciseFilters from "../ExerciseFilters/ExerciseFilters";
import Video from "../../Video/Video";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";

import { post } from "../../api";

const videoFolderName = "video/";

const queryCreateExercise = {
  query: `
    mutation ($input: ExerciseDefinitionInput!) {
      createExercise(input: $input) {
        id
      }
    }
  `
};
const queryDeleteExercise = {
  query: `
    mutation ($id: Int!) {
      deleteExercise_definition(id: $id)
    }
  `
};

const queryUpdateExercise = {
  query: `
    mutation ($id: Int!, $input: ExerciseDefinitionInput!) {
      updateExercise_definition(id: $id, input: $input) {
        id
      }
    }
  `
};

const queryExercise = {
  query: `
    query ($id: Int!) {
      exercise_definition(id: $id) {
        id  
        name
        difficulty
        video
        used_in {
          name
          id
        }
        muscles {
          id
          name
          definition_id
        }
        equipments {
          id
          name
          definition_id
        }
      }
    }
  `
};

const queryAssignMuscle = {
  query: `
    mutation ($exerciseId: Int!, $muscleId: Int!) {
      assignMuscle(exercise_id: $exerciseId, muscle_id: $muscleId)
    }
  `
};

const queryAssignEquipment = {
  query: `
    mutation ($exerciseId: Int!, $equipmentId: Int!) {
      assignEquipment(exercise_id: $exerciseId, equipment_id: $equipmentId)
    }
  `
};

const queryDeleteMuscleAssignment = {
  query: `
    mutation ($id: Int!) {
      deleteMuscleAssignment(id: $id)
    }
  `
};
const queryDeleteEquipmentAssignment = {
  query: `
    mutation ($id: Int!) {
      deleteEquipmentAssignment(id: $id)
    }
  `
};

export function ExerciseEditor({ match, newMode, editMode, history }) {
  const [filterValues, setFilterValues] = useState();
  const [exercise, setExercise] = useState();
  const [name, setName] = useState("");
  const [uploadedVideoFile, setUploadedVideoFile] = useState();
  const [updateState, setUpdateState] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  let exerciseId;
  if (match && !newMode) {
    exerciseId = parseInt(match.params.id);
  }

  useEffect(() => {
    if (exerciseId && !exercise && !newMode) {
      fetchExercise();
    }
  }, [exerciseId, newMode, exercise]);

  function updateFilters(newFilters) {
    setFilterValues(newFilters);
  }

  function onChangeHandler(e) {
    setUploadedVideoFile(e.target.files[0]);
  }

  function onSaveClick() {
    setUpdateState("pending");

    if (newMode) {
      createExercise();
    } else {
      updateExercise();
    }
  }

  function onDeleteClick() {
    setShowDeleteModal(true);
  }

  function fetchExercise() {
    post(`/api/graphql`, {
      ...queryExercise,
      variables: { id: exerciseId }
    }).then(response => {
      if (response.data.errors) {
        setExercise(null);
      } else {
        const exercise = response.data.data.exercise_definition;
        setName(exercise.name);
        setExercise(exercise);
      }
    });
  }

  async function saveFilters(newExerciseId) {
    let id = exerciseId;

    if (newExerciseId !== undefined) {
      id = newExerciseId;
    }

    const requestPromises = [];
    filterValues.muscles.forEach(muscle => {
      let musclePresence;
      if (!newMode) {
        musclePresence = exercise.muscles.find(
          exerciseMuscle => exerciseMuscle.definition_id === muscle.id
        );
      }
      if (muscle.checked) {
        if (!musclePresence) {
          requestPromises.push(
            post(`/api/graphql`, {
              ...queryAssignMuscle,
              variables: { exerciseId: id, muscleId: muscle.id }
            })
          );
        }
      } else {
        if (musclePresence) {
          requestPromises.push(
            post(`/api/graphql`, {
              ...queryDeleteMuscleAssignment,
              variables: { id: musclePresence.id }
            })
          );
        }
      }
    });
    filterValues.equipments.forEach(equipment => {
      let equipmentPresence;
      if (!newMode) {
        equipmentPresence = exercise.equipments.find(
          exerciseEquipment => exerciseEquipment.definition_id === equipment.id
        );
      }

      if (equipment.checked) {
        if (!equipmentPresence) {
          requestPromises.push(
            post(`/api/graphql`, {
              ...queryAssignEquipment,
              variables: { exerciseId: id, equipmentId: equipment.id }
            })
          );
        }
      } else {
        if (equipmentPresence) {
          requestPromises.push(
            post(`/api/graphql`, {
              ...queryDeleteEquipmentAssignment,
              variables: { id: equipmentPresence.id }
            })
          );
        }
      }
    });
    await Promise.all(requestPromises);
  }

  async function updateExercise() {
    let video = exercise.video;

    if (uploadedVideoFile) {
      await deleteExistingVideo(video);
      const uploadResult = await addVideo(uploadedVideoFile);
      video = uploadResult.Location;
    }

    await saveFilters();

    let newDifficulty = null;
    let selectedDifficultyOption = filterValues.difficulties.find(
      difficulty => difficulty.checked
    );
    if (selectedDifficultyOption) {
      newDifficulty = selectedDifficultyOption.id;
    }

    const variables = {
      id: exercise.id,
      input: { name, video, difficulty: newDifficulty }
    };

    post(`/api/graphql`, {
      ...queryUpdateExercise,
      variables
    })
      .then(() => {
        setTimeout(() => {
          setUpdateState("success");
        }, 500);

        setTimeout(() => {
          setUpdateState();
        }, 2500);
        fetchExercise();
      })
      .catch(() => {
        setTimeout(() => {
          setUpdateState("error");
        }, 500);

        setTimeout(() => {
          setUpdateState();
        }, 2500);
      });
  }

  function deleteExercise() {
    post(`/api/graphql`, {
      ...queryDeleteExercise,
      variables: { id: exerciseId }
    }).then(response => {
      if (response.data.errors) {
        alert("There has been an error while deleting this exercise");
      } else {
        history.push("/admin/exercise_definitions");
      }
    });
  }

  async function createExercise() {
    const uploadResult = await addVideo(uploadedVideoFile);
    let newDifficulty = null;
    let selectedDifficultyOption = filterValues.difficulties.find(
      difficulty => difficulty.checked
    );
    if (selectedDifficultyOption) {
      newDifficulty = selectedDifficultyOption.id;
    }

    const variables = {
      input: {
        name,
        difficulty: newDifficulty,
        video: uploadResult.Location
      }
    };

    post(`/api/graphql`, {
      ...queryCreateExercise,
      variables
    }).then(async response => {
      const newExerciseId = response.data.data.createExercise.id;

      await saveFilters(newExerciseId);

      history.push(`/admin/exercise_definition/${newExerciseId}`);
    });
  }

  function displayFileUploadBox() {
    return (
      <div className="video-upload-container column">
        <input type="file" name="file" onChange={onChangeHandler} />
        {uploadedVideoFile ? (
          <p className="uploaded-video-path">{uploadedVideoFile.name}</p>
        ) : (
          <p className="upload-video-instruction">
            Drag a new video file here
            <br />
            (or click to choose one)
          </p>
        )}
      </div>
    );
  }

  function displayDeleteModal() {
    if (!showDeleteModal) {
      return null;
    }
    let content = null;
    if (exercise.used_in.length === 0) {
      content = (
        <>
          <h3>Are you sure you want to delete this exercise?</h3>
          <div className="use-message">
            <p>This exercise is not used anywhere</p>
          </div>
          <div className="button-container">
            <Button
              label="No, keep it"
              icon={<i className="fas fa-check" />}
              type="secondary"
              onClick={() => setShowDeleteModal(false)}
            />
            <Button
              label="Yes, delete"
              icon={<i className="fas fa-trash" />}
              className="delete"
              type="primary"
              onClick={() => {
                setShowDeleteModal(false);
                deleteExercise();
              }}
            />
          </div>
        </>
      );
    } else {
      content = (
        <>
          <h3>Exercise cannot be deleted</h3>
          <div className="use-message">
            <p>This exercise is used in the following circuits:</p>
            <div>
              <ul>
                {exercise.used_in.map(circuit => (
                  <li key={circuit.name}>
                    <Link to={`/admin/circuit_definition/${circuit.id}`}>
                      {circuit.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="button-container single">
              <Button
                label="Ok, I understand"
                icon={<i className="fas fa-check" />}
                type="secondary"
                onClick={() => setShowDeleteModal(false)}
              />
            </div>
          </div>
        </>
      );
    }

    return (
      <Modal onClose={() => setShowDeleteModal(false)} className="delete-modal">
        {content}
      </Modal>
    );
  }

  function displayCurrentVideoBox() {
    if (newMode) {
      return null;
    }
    let imgElement = null;

    if (!newMode) {
      if (!exercise) {
        imgElement = <label>Loading...</label>;
      } else if (exercise.video) {
        imgElement = <Video src={exercise.video} className="video-element" />;
      } else {
        imgElement = <label>No video yet</label>;
      }
    } else {
      if (exercise && exercise.video) {
        imgElement = <Video src={exercise.video} className="video-element" />;
      }
    }

    return (
      <div className="current-video-box column">
        <p className="upload-video-instruction">Current video:</p>
        {imgElement}
      </div>
    );
  }

  let hasName = !!name.length;
  let hasVideo = exercise ? exercise.video : !!uploadedVideoFile;
  let hasDifficulty = false;

  if (filterValues) {
    hasDifficulty = filterValues.difficulties.some(item => item.checked);
  }

  let saveButtonEnabled = hasName && hasVideo && hasDifficulty;

  let updateExerciseLabel = newMode ? "Save" : "Update";

  if (updateState === "pending") {
    updateExerciseLabel = "Saving...";
  } else if (updateState === "success") {
    updateExerciseLabel = "Exercise updated!";
  } else if (updateState === "error") {
    updateExerciseLabel = "Update failed!";
  }

  if (!newMode) {
    if (exercise === undefined) {
      return <p>Loading...</p>;
    }
    if (exercise === null) {
      return <p>There is no exercise with the id {exerciseId}</p>;
    }
  }

  return (
    <div className="exercise-editor">
      <div className="save-delete-buttons">
        <Button
          icon={<i className="fas fa-save" />}
          label={updateExerciseLabel}
          type="primary"
          onClick={onSaveClick}
          enabled={saveButtonEnabled}
        />
        {editMode && (
          <Button
            icon={<i className="fas fa-trash" />}
            label="Delete exercise"
            className="delete"
            type="primary"
            onClick={onDeleteClick}
          />
        )}
      </div>
      <div className="details-row">
        <label>Name</label>
        <input onChange={e => setName(e.target.value)} value={name} />
      </div>
      <div className="video-row">
        {displayFileUploadBox()}
        {displayCurrentVideoBox()}
      </div>
      <ExerciseFilters
        onChange={updateFilters}
        exerciseId={exerciseId}
        chooseMode={false}
      />
      {displayDeleteModal()}
    </div>
  );
}

function deleteExistingVideo(video) {
  return new Promise((resolve, reject) => {
    const fullPath = video;
    const fullPathComponents = fullPath.split("/");
    const fileName = fullPathComponents[fullPathComponents.length - 1];

    const params = {
      Bucket: window.S3BucketName,
      Key: videoFolderName + fileName
    };

    const s3 = new window.AWS.S3();
    s3.deleteObject(params, function(err, data) {
      if (err) {
        console.log(err, err.stack);
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

function addVideo(file) {
  let fileName = file.name.toLowerCase();
  const charactersToReplace = [" ", "-", ","];
  charactersToReplace.forEach(
    char => (fileName = fileName.split(char).join("_"))
  );

  let fileKey =
    videoFolderName +
    Date.now() +
    "_" +
    Math.floor(Math.random() * 1000000) +
    "_" +
    fileName;

  // Use S3 ManagedUpload class as it supports multipart uploads
  let upload = new window.AWS.S3.ManagedUpload({
    params: {
      Bucket: window.S3BucketName,
      Key: fileKey,
      Body: file,
      ACL: "public-read"
    }
  });

  return upload.promise();
}

export default withRouter(ExerciseEditor);
