import React, { Component } from "react";
import cx from "classnames";

import "./ViewType.scss";
import heartIcon from "../../assets/heart.png";
import switchIcon from "../../assets/switch.png";
import lungsIcon from "../../assets/lungs.png";
import titleWork from "../../assets/work.png";
import titleSwitch from "../../assets/switch-title.png";
import titleRest from "../../assets/rest.png";

export default class ViewType extends Component {
  displayContent = () => {
    const { currentView } = this.props;
    let heartClassName = "soft-hidden";
    let switchClassName = "soft-hidden";
    let lungsClassName = "soft-hidden";

    if (currentView) {
      switch (currentView.type) {
        case "video":
          heartClassName = "visible";
          break;
        case "switch":
          switchClassName = "visible";
          break;
        case "rest":
          lungsClassName = "visible";
          break;
        default:
          break;
      }
    }

    return (
      <>
        <img
          className={cx("title", "work-title", heartClassName)}
          src={titleWork}
          alt=""
        />
        <img
          className={cx("title", "switch-title", switchClassName)}
          src={titleSwitch}
          alt=""
        />
        <img
          className={cx("title", "rest-title", lungsClassName)}
          src={titleRest}
          alt=""
        />
        <div className="image-container">
          <img
            className={cx("heart-icon", heartClassName)}
            src={heartIcon}
            alt=""
          />
          <img
            className={cx("switch-icon", switchClassName)}
            src={switchIcon}
            alt=""
          />
          <img
            className={cx("rest-icon", lungsClassName)}
            src={lungsIcon}
            alt=""
          />
        </div>
      </>
    );
  };

  displayRoundNumber = () => {
    const { currentView } = this.props;
    if (!currentView || currentView.type === "switch") {
      return null;
    }
    const round = this.props.currentView.round;
    const label = (
      <span>
        <span className="number">{round.split("/")[0]}</span>
        <span className="separator"> of </span>
        <span className="number">{round.split("/")[1]}</span>
      </span>
    );

    return <h2 className="round-number">{label}</h2>;
  };
  render() {
    const { currentView } = this.props;

    const switchClassName =
      currentView && currentView.type === "switch" ? "switch-class" : "";

    return (
      <div className={`view-type ${switchClassName}`}>
        {this.displayContent()}
        {this.displayRoundNumber()}
      </div>
    );
  }
}
