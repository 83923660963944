import React from "react";
import { get, post } from "../api";
import "./PlaybackContainer.scss";
import { withRouter } from "react-router-dom";
import {
  buildTimeline,
  prefetchVideos,
  prefetchAndPreloadSounds
} from "./playbackUtilities.js";

import { getCurrentSeconds } from "../utilities";
import {
  SECONDS_LEFT_BEEP,
  RESYNC_TIME_DELAY,
  NO_CIRCUIT_CHECK_INTERVAL
} from "./constants";

import moment from "moment";
import NoCurrentView from "./NoCurrentView/NoCurrentView";
import GroupExercise from "./GroupExercise/GroupExercise";
import WarmUp from "./WarmUp/WarmUp";
import Station from "./Station/Station";

import heartIcon from "../assets/heart.png";
import switchIcon from "../assets/switch.png";
import lungsIcon from "../assets/lungs.png";
import titleWork from "../assets/work.png";
import titleSwitch from "../assets/switch-title.png";
import titleRest from "../assets/rest.png";

const queryDate = {
  query: `
    query ($date: String!) {
      date(date: $date) {
        start_time
        end_time
        warmup_rounds
        warmup_workout_time
        warmup_rest_time
        warmup_switch_time
        station_rounds
        station_workout_time
        station_rest_time
        station_switch_time
        station_group_time

        stations {
          station_number
          exercises {
            video
            order_index
          }
        }
      }
    }
`
};

export class PlaybackContainer extends React.Component {
  // webSocket = null;
  socketIsOpen = false;
  checkViewInterval = null;
  tickInterval = null;
  time = getCurrentSeconds();

  state = {
    lastSecond: null,
    lastView: null,
    currentTime: null,
    currentView: undefined,
    timeLeft: 0,
    circuit: null,
    station: null,
    groupExerciseStation: null,
    timeline: null,
    error: null
  };

  componentDidMount() {
    this.fetchCircuit();
    setInterval(this.fetchTimeOffset, RESYNC_TIME_DELAY * 1000);
    this.fetchTimeOffset();
    // this.openWebSocketConnection();
  }

  componentWillUnmount() {
    if (this.checkViewInterval) {
      clearInterval(this.checkViewInterval);
    }
    if (this.tickInterval) {
      clearInterval(this.tickInterval);
    }
  }

  fetchCircuit = () => {
    let dateInUrl = this.props.match.params.date;
    const today = moment(dateInUrl).format("YYYY-MM-DD");
    this.setState({
      circuit: null,
      station: null,
      groupExerciseStationWithBlobUrls: null,
      timeline: null,
      error: null
    });

    post("/api/graphql", { ...queryDate, variables: { date: today } }).then(
      response => {
        const circuit = response.data.data.date;
        this.initialisePlayback(circuit);
      }
    );
  };

  initialisePlayback = async circuit => {
    const stationNumber = parseInt(this.props.match.params.stationNumber);

    if (!circuit) {
      this.setState({ error: "no_circuit" });
      setTimeout(this.props.refresh, NO_CIRCUIT_CHECK_INTERVAL * 1000);
      return;
    }

    const stationData = circuit.stations.find(
      station => station.station_number === stationNumber
    );

    if (stationData.exercises.length === 0) {
      this.setState({ error: "no_exercises" });
      return;
    }

    stationData.exercises.sort((a, b) =>
      a.order_index > b.order_index ? 1 : -1
    );

    const groupExerciseStation = circuit.stations.find(
      station => station.station_number === 100
    );

    const stationDataWithBlobUrls = await prefetchVideos(stationData);
    await prefetchAndPreloadSounds();

    // we never have group exercises in the warmup station
    let groupExerciseStationWithBlobUrls = null;
    if (stationData.station_number !== 0) {
      groupExerciseStationWithBlobUrls = await prefetchVideos(
        groupExerciseStation
      );
    }
    // console.log("circuit = ", circuit);
    // console.log("stationDataWithBlobUrls = ", stationDataWithBlobUrls);

    const timeline = buildTimeline({
      circuit,
      stationData: stationDataWithBlobUrls,
      stationNumber
    });

    this.setState(
      {
        timeline,
        circuit,
        station: stationDataWithBlobUrls,
        groupExerciseStation: groupExerciseStationWithBlobUrls
      },
      () => {
        this.checkViewInterval = setInterval(this.checkView, 200);
      }
    );
  };

  // openWebSocketConnection = () => {
  //   const url = `ws://${window.location.hostname}:5005`;
  //   this.webSocket = new WebSocket(url);

  //   this.webSocket.onopen = () => {
  //     console.log("Web socket connection established");
  //     this.socketIsOpen = true;
  //     this.webSocket.send(
  //       "Here's some text that the server is urgently awaiting!"
  //     );
  //   };

  //   this.webSocket.onmessage = event => {
  //     const message = JSON.parse(event.data);
  //     if (message.name === "init") {
  //     } else if (message.name === "refresh") {
  //       window.location.reload();
  //     }
  //   };

  //   this.webSocket.onclose = () => {
  //     setTimeout(this.openWebSocketConnection, 2000);
  //   };
  // };

  getTimezoneOffsetInSeconds = () => {
    const now = new Date();
    return now.getTimezoneOffset() * 60;
  };

  tick = () => {
    this.time += 1;
    this.props.tick(this.props.id);
  };

  restartTick = serverResponse => {
    if (this.tickInterval) {
      clearInterval(this.tickInterval);
    }
    const restartDelay = 1000 - (serverResponse.milliseconds || 0);

    setTimeout(() => {
      this.time = serverResponse.time;
      this.tickInterval = setInterval(this.tick, 1000);
    }, restartDelay);
  };

  fetchTimeOffset = () => {
    // const host = "http://hiit-tribe-dev-1442717669.eu-west-2.elb.amazonaws.com";
    const host = "";
    const url = `${host}/time_offset/?time=${new Date().getTime()}`;

    get(url)
      .then(response => {
        const milliseconds = response.data.milliseconds;

        this.restartTick(response.data);
      })
      .catch(e => {
        // nothing to do, just wait for the next one
      });
  };

  checkView = () => {
    const { circuit, lastSecond, lastView, station, error } = this.state;

    if (!circuit || error) {
      return;
    }

    const { currentView, timeLeft } = this.getCurrentView(this.time);

    if (station.station_number !== 0) {
      if (
        lastSecond !== null &&
        lastSecond !== undefined &&
        timeLeft !== lastSecond &&
        timeLeft === SECONDS_LEFT_BEEP
      ) {
        this.playRegularBeep();
      }

      if (
        lastView &&
        currentView &&
        lastView.type !== currentView.type &&
        currentView.type === "group"
      ) {
        this.playGroupBeep();
      }
    }

    this.setState({
      currentView,
      timeLeft,
      currentTime: this.time,
      lastSecond: timeLeft,

      lastView: { ...currentView }
    });
  };

  playRegularBeep = () => {
    try {
      window.regularBeep.play();
    } catch (e) {}
  };

  playGroupBeep = () => {
    try {
      window.groupExerciseBeep.play();
    } catch (e) {}
  };

  getCurrentView = currentTime => {
    const { circuit, timeline } = this.state;

    let currentView = null;
    let timeLeft = 0;

    if (currentTime > circuit.start_time && currentTime < circuit.end_time) {
      for (let i = 0; i < timeline.length; i++) {
        const period = timeline[i];
        const periodStart = circuit.start_time + period.start;
        const periodEnd = periodStart + period.duration;
        if (periodStart <= currentTime && periodEnd > currentTime) {
          currentView = period;
          timeLeft = periodEnd - currentTime;
          break;
        }
      }
    }

    return {
      currentView,
      timeLeft
    };
  };

  displayHiddenViewTypeImages = () => {
    return (
      <div className="hidden-view-type-images-container">
        <img src={heartIcon} />
        <img src={switchIcon} />
        <img src={lungsIcon} />
        <img src={titleWork} />
        <img src={titleSwitch} />
        <img src={titleRest} />
      </div>
    );
  };

  displayNoCurrentView = () => {
    const { currentView } = this.state;
    if (currentView) {
      return null;
    }
    return <NoCurrentView {...this.state} currentView={currentView} />;
  };

  displayContent = () => {
    const { currentView, timeLeft, station, circuit } = this.state;

    if (!circuit || !station || !currentView || currentView.type === "group") {
      return null;
    }

    switch (station.station_number) {
      case 0:
        return (
          <WarmUp
            timeLeft={timeLeft}
            station={station}
            currentView={currentView}
          />
        );

      case 1:
      case 2:
      case 3:
      case 4:
        return (
          <Station
            timeLeft={timeLeft}
            station={station}
            currentView={currentView}
          />
        );

      default:
        return (
          <h1 className="playback-message"> Not a valid station number </h1>
        );
    }
  };

  displayError = () => {
    const { error } = this.state;

    if (!error) {
      return null;
    }

    let dateInUrl = this.props.match.params.date;
    const today = moment(dateInUrl).format("YYYY-MM-DD");

    switch (error) {
      case "no_circuit":
        return (
          <div className="playback-container">
            <h1 className="playback-message">
              Today, {today} has no circuit associated with it.
            </h1>
          </div>
        );

      case "no_exercises":
        return (
          <div className="playback-container">
            <h1 className="playback-message">
              This station has no exercises associated for today, {today}.
            </h1>
          </div>
        );
      default:
        return null;
    }
  };

  displayGroupExercises = () => {
    const { currentView, timeLeft, station, groupExerciseStation } = this.state;

    if (!groupExerciseStation || !currentView || currentView.type !== "group") {
      return null;
    }

    if (station.station_number === 0) {
      // we never have group exercises for warmup
      return null;
    }

    if (currentView.type === "group") {
      const exercise = groupExerciseStation.exercises.find(
        exercise => exercise.order_index === currentView.exerciseIndex
      );

      return (
        <GroupExercise
          exercise={exercise}
          timeLeft={timeLeft}
          currentView={currentView}
        />
      );
    }
  };

  render() {
    const { currentView, circuit, error } = this.state;

    if (error) {
      return this.displayError();
    }

    if (!circuit) {
      return <h1 className="playback-message">Loading...</h1>;
    } else if (currentView === undefined) {
      return <h1 className="playback-message">Loading...</h1>;
    }

    return (
      <div className="playback-container">
        {this.displayHiddenViewTypeImages()}
        {this.displayContent()}
        {this.displayGroupExercises()}
        {this.displayNoCurrentView()}
      </div>
    );
  }
}

export default withRouter(PlaybackContainer);
