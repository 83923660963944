import React from "react";
import { Link, withRouter } from "react-router-dom";

import "./Sidebar.scss";

export function Sidebar({ match }) {
  let selectedSection = "calendar";
  if (window.location.href.includes("circuit")) {
    selectedSection = "circuit";
  } else if (window.location.href.includes("exercise")) {
    selectedSection = "exercise";
  } else if (window.location.href.includes("equipment")) {
    selectedSection = "equipment";
  } else if (window.location.href.includes("muscle")) {
    selectedSection = "muscle";
  } else if (window.location.href.includes("playback")) {
    selectedSection = "playback";
  }

  return (
    <div className="sidebar-container">
      <Link
        to="/admin"
        className={selectedSection === "calendar" ? "selected" : ""}
      >
        <i className="fas fa-calendar-alt"></i>
        <p>Calendar</p>
      </Link>

      <Link
        to="/admin/circuit_definitions"
        className={selectedSection === "circuit" ? "selected" : ""}
      >
        <i className="fas fa-recycle"></i>
        <p>Circuits</p>
      </Link>

      <Link
        to="/admin/exercise_definitions"
        className={selectedSection === "exercise" ? "selected" : ""}
      >
        <i className="fas fa-running"></i>
        <p>Exercises</p>
      </Link>
      <Link
        to="/admin/equipment_definitions"
        className={selectedSection === "equipment" ? "selected" : ""}
      >
        <i className="fas fa-dumbbell"></i>
        <p>Equipment</p>
      </Link>
      <Link
        to="/admin/muscle_definitions"
        className={selectedSection === "muscle" ? "selected" : ""}
      >
        <i className="fas fa-hand-rock"></i>
        <p>Muscles</p>
      </Link>
      <Link
        to="/admin/playback"
        className={selectedSection === "playback" ? "selected" : ""}
      >
        <i className="fas fa-play-circle"></i>
        <p>Playback</p>
      </Link>
    </div>
  );
}

export default withRouter(Sidebar);
