import React from "react";
import { drawArc } from "./SVGArcHelpers";

/**
  props:
    radius
    color
    angle
**/

const SVGArc = props => {
  const { strokeWidth, startAngle, endAngle } = props;
  const boxSize = props.radius * 2 + strokeWidth * 2;
  const viewBox = [0, 0, boxSize, boxSize].join(" ");

  const d = drawArc({
    x: strokeWidth,
    y: strokeWidth,
    radius: props.radius,
    startAngle,
    endAngle
  });

  const transform = `translate(${props.radius} ${props.radius})`;

  const style = {
    position: "absolute",
    width: `${boxSize}px`,
    height: `${boxSize}px`
  };

  return (
    <svg className={props.className} style={style} viewBox={viewBox}>
      <g transform={transform}>
        <path
          d={d}
          stroke={`${props.color}`}
          strokeWidth={strokeWidth}
          fill="none"
        />
      </g>
    </svg>
  );
};

export default SVGArc;
