import React from "react";
import ReactDOM from "react-dom";
import "./frontend/index.css";
import App from "./frontend/App";
import axios from "axios";
import * as serviceWorker from "./frontend/serviceWorker";
import { CognitoAuth } from "amazon-cognito-auth-js";

import * as Sentry from "@sentry/browser";

const uploadVideoS3BucketRegion = "eu-west-2";

var padStart = require("string.prototype.padstart");

async function start() {
  padStart.shim();

  if (needToRedirect()) {
    redirectToHttps();
  } else {
    initialiseSentry();

    if (window.location.pathname.includes("/playback/station")) {
      // we do not want to require authentication for the playback side of the app
      loadApp();
    } else {
      authenticate();
    }
  }
}

function needToRedirect() {
  if (window.location.hostname !== "localhost") {
    console.log("Not localhost");
    if (!window.location.protocol.includes("https")) {
      return true;
    } else {
      console.log("Already https, not redirecting");
      return false;
    }
  }
}

function redirectToHttps() {
  console.log("Redirecting to https");
  window.location.href = window.location.href.split("http://").join("https://");
}

function initialiseSentry() {
  const SENTRY_DSN_ENVS = {
    test: "https://2730d44d46b2460eb5bde725440676ca@sentry.io/1886086",
    prod: "https://b50819288369445bb892dcb8d9e23360@sentry.io/1862405"
  };

  let sentryDsn = null;

  if (window.location.hostname === "localhost") {
    sentryDsn = SENTRY_DSN_ENVS["test"];
  } else {
    sentryDsn = SENTRY_DSN_ENVS["prod"];
  }
  Sentry.init({
    dsn: sentryDsn
  });
}

async function authenticate() {
  /*
  TokenScopesArray
  Valid values are found under:
  AWS Console -> User Pools -> <Your user pool> -> App Integration -> App client settings
  Example values: ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin', 'phone']

  RedirectUriSignOut 
  This value must match the value specified under:
  AWS Console -> User Pools -> <Your user pool> -> App Integration -> App client settings -> Sign out URL(s)
*/

  const {
    cognitoUserPoolId,
    cognitoClientId,
    cognitoAppWebDomain
  } = await getEnvironmentBeforeAuth();

  const host = window.location.hostname;
  const protocol = window.location.protocol;
  let port = "";

  if (host === "localhost") {
    port = `:${window.location.port}`;
  }

  var authData = {
    ClientId: cognitoClientId, // Your client id here
    AppWebDomain: cognitoAppWebDomain,
    TokenScopesArray: ["email"], // e.g.['phone', 'email', 'profile','openid', 'aws.cognito.signin.user.admin'],
    RedirectUriSignIn: `${protocol}//${host}${port}`,
    RedirectUriSignOut: `${protocol}//${host}${port}`,
    IdentityProvider: "Cognito", // e.g. 'Facebook',
    UserPoolId: cognitoUserPoolId, // Your user pool id here
    AdvancedSecurityDataCollectionFlag: false // e.g. true
  };
  console.log(JSON.stringify(authData));
  var auth = new CognitoAuth(authData);

  auth.userhandler = {
    onSuccess: function(result) {
      loadApp();
    },
    onFailure: function(err) {
      alert(err);
    }
  };

  var curUrl = window.location.href;
  auth.parseCognitoWebResponse(curUrl);

  auth.getSession();

  window.auth = auth;
}

async function getEnvironmentBeforeAuth() {
  return await new Promise(resolve => {
    axios.get("/api/get_environment_before_auth").then(response => {
      resolve(response.data);
    });
  });
}

function loadApp() {
  axios.get("/api/get_environment_after_auth").then(response => {
    window.env = response.data.env;
    window.cognitoIdentityPoolId = response.data.cognitoIdentityPoolId;
    window.S3BucketName = response.data.videoS3Bucket;
    window.S3BucketPath = `https://${window.S3BucketName}.s3.eu-west-2.amazonaws.com`;

    window.AWS.config.update({
      region: uploadVideoS3BucketRegion,
      credentials: new window.AWS.CognitoIdentityCredentials({
        IdentityPoolId: window.cognitoIdentityPoolId
      })
    });

    ReactDOM.render(<App />, document.getElementById("root"));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  });
}

start();
