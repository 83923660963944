export const queryCircuit = {
  query: `
    query ($id: Int!) {
      circuit_definition(id: $id) {
        id
        name
        is_used
        used_in {
          date
        }
        station0_checks_ok
        station1_checks_ok
        station2_checks_ok
        station3_checks_ok
        station4_checks_ok
        station100_checks_ok
        station_checks_ok

        warmup_rounds
        warmup_workout_time
        warmup_rest_time
        warmup_switch_time
        station_rounds
        station_workout_time
        station_rest_time
        station_switch_time
        station_group_time

        stations {
          id  
          station_number
          definition_id
        }
      }
    }
  `
};

export const queryUpdateCircuit = {
  query: `
    mutation ($id: Int!, $input: CircuitInput!) {
      updateCircuit_definition(id: $id, input: $input) {
        id
      }
    }
  `
};

export const queryDeleteCircuit = {
  query: `
    mutation ($id: Int!) {
      deleteCircuit_definition(id: $id)
    }
  `
};
