import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { post } from "../../api";
import moment from "moment";

import {
  WARMUP_TARGET_TOTAL_TIME,
  STATION_TARGET_TOTAL_TIME,
  WARMUP_TARGET_EXERCISES,
  STATION_TARGET_EXERCISES
} from "../constants.js";

import "./CircuitDetails.scss";

import StationDetails from "../StationDetails/StationDetails.jsx";
import Button from "../Button/Button.jsx";
import Modal from "../Modal/Modal.jsx";

import {
  queryCircuit,
  queryUpdateCircuit,
  queryDeleteCircuit
} from "./QueriesCircuitDetails";

export function CircuitDetails({ id, match, history, editMode }) {
  const [circuit, setCircuit] = useState();
  const [name, setName] = useState("");
  const [selectedStation, setSelectedStation] = useState();
  const [updateState, setUpdateState] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [totals, setTotals] = useState({
    warmup_workout_time: 0,
    warmup_rest_time: 0,
    warmup_switch_time: 0,
    station_workout_time: 0,
    station_rest_time: 0,
    station_switch_time: 0,
    station_group_time: 0
  });

  let circuitId = id;

  if (editMode && match) {
    circuitId = parseInt(match.params.id);
  }

  useEffect(() => {
    if (!circuit) {
      return;
    }

    const warmupTotals = computeTotals("warmup");
    const stationTotals = computeTotals("station");

    const warmupChecksOK =
      warmupTotals.warmup_total_time === WARMUP_TARGET_TOTAL_TIME ||
      warmupTotals.warmup_total_time === 0;
    console.log(
      "warmupTotals.warmup_total_time = ",
      warmupTotals.warmup_total_time
    );
    let stationChecksOK = true;
    if (stationTotals.station_total_time !== STATION_TARGET_TOTAL_TIME) {
      stationChecksOK = false;
    }
    if (circuit.station_group_time >= circuit.station_switch_time - 1) {
      stationChecksOK = false;
    }

    const checksOK = warmupChecksOK && stationChecksOK;
    setTotals({ ...warmupTotals, ...stationTotals });

    if (
      warmupChecksOK !== circuit.warmup_checks_ok ||
      stationChecksOK !== circuit.station_checks_ok ||
      checksOK !== circuit.checks_ok
    ) {
      setCircuit({
        ...circuit,
        warmup_checks_ok: warmupChecksOK,
        station_checks_ok: stationChecksOK,
        checks_ok: checksOK
      });
    }
  }, [circuit]);

  function computeTotals(prefix) {
    const rounds = circuit[`${prefix}_rounds`] || 0;
    const workoutTime = circuit[`${prefix}_workout_time`] || 0;
    const restTime = circuit[`${prefix}_rest_time`] || 0;
    const switchTime = circuit[`${prefix}_switch_time`] || 0;

    const nonDeletedExerciseCount =
      prefix === "warmup" ? WARMUP_TARGET_EXERCISES : STATION_TARGET_EXERCISES;

    const newTotalWorkoutTime = rounds * workoutTime * nonDeletedExerciseCount;

    const newTotalRestTime = Math.max(
      rounds * restTime * nonDeletedExerciseCount - restTime,
      0
    );

    const newTotalSwitchTime = switchTime;

    const newTotalTime =
      newTotalWorkoutTime + newTotalRestTime + newTotalSwitchTime;

    return {
      [`${prefix}_workout_time`]: newTotalWorkoutTime,
      [`${prefix}_rest_time`]: newTotalRestTime,
      [`${prefix}_switch_time`]: newTotalSwitchTime,
      [`${prefix}_total_time`]: newTotalTime
    };
  }

  useEffect(() => {
    setCircuit();
    fetchSingleCircuit();
  }, [circuitId]);

  function fetchSingleCircuit() {
    post(`/api/graphql`, {
      ...queryCircuit,
      variables: { id: circuitId }
    }).then(response => {
      if (response.data.errors) {
        setCircuit(null);
        return;
      }

      const circuit = response.data.data.circuit_definition;
      setCircuit(circuit);

      setName(circuit.name || "");

      let preselectedStationNumber = 0;
      if (match.params.stationId !== undefined) {
        preselectedStationNumber = match.params.stationId;
      }

      selectStationByNumber({
        stationNumber: preselectedStationNumber,
        circuit
      });
    });
  }

  function selectStationByNumber({ stationNumber, circuit }) {
    if (!isNaN(stationNumber)) {
      stationNumber = parseInt(stationNumber);
    }
    if (stationNumber === "group") {
      setSelectedStation({ station_number: "group" });
    } else {
      const preselectedStation = circuit.stations.find(
        station => station.station_number === stationNumber
      );

      setSelectedStation(preselectedStation);
    }
  }

  function updateCircuit() {
    const circuitVariables = { ...circuit };
    // circuitVariables.time_checks_ok = circuitVariables.checks_ok;

    const variables = {
      id: circuitId,
      input: {
        name,
        time_checks_ok: circuitVariables.checks_ok,
        warmup_rounds: circuitVariables.warmup_rounds,
        warmup_workout_time: circuitVariables.warmup_workout_time,
        warmup_rest_time: circuitVariables.warmup_rest_time,
        warmup_switch_time: circuitVariables.warmup_switch_time,
        station_rounds: circuitVariables.station_rounds,
        station_workout_time: circuitVariables.station_workout_time,
        station_rest_time: circuitVariables.station_rest_time,
        station_switch_time: circuitVariables.station_switch_time,
        station_group_time: circuitVariables.station_group_time
      }
    };

    setUpdateState("pending");

    post(`/api/graphql`, {
      ...queryUpdateCircuit,
      variables
    })
      .then(() => {
        setTimeout(() => {
          setUpdateState("success");
        }, 500);

        setTimeout(() => {
          setUpdateState();
        }, 2500);
        fetchSingleCircuit();
      })
      .catch(() => {
        setUpdateState("error");
        setTimeout(() => {
          setUpdateState();
        }, 2500);
      });
  }

  function deleteCircuit() {
    post(`/api/graphql`, {
      ...queryDeleteCircuit,
      variables: { id: circuitId }
    }).then(response => {
      history.push("/admin/circuit_definitions");
    });
  }

  function updateSelectedStationStatus(newStatus) {
    const newStations = circuit.stations.map(station => {
      let newChecksStatus = circuit.stations.checks_ok;
      if (station.station_number === selectedStation.station_number) {
        newChecksStatus = newStatus;
      }
      return { ...station, checks_ok: newChecksStatus };
    });

    const stationKey = `station${selectedStation.station_number}_checks_ok`;
    setCircuit({
      ...circuit,
      stations: newStations,
      [stationKey]: newStatus
    });
  }

  function displayStations() {
    const stationNumbers = [0, 100, 1, 2, 3, 4];
    return stationNumbers.map(stationNumber => {
      let content = "";

      if (stationNumber === 0) {
        content = "Warmup";
      } else if (stationNumber === 100) {
        content = "Group Exercises";
      } else {
        content = `Station ${stationNumber}`;
      }
      let className = "station-tab";
      if (selectedStation && stationNumber === selectedStation.station_number) {
        className += " selected";
      }

      let tabIcon;
      if (stationNumber === "group") {
        tabIcon = <i className="fas fa-check-circle" />;
      } else {
        const stationIsReady = circuit[`station${stationNumber}_checks_ok`];

        if (stationIsReady) {
          className += " ready";
        } else {
          className += " not-ready";
        }

        tabIcon = stationIsReady ? (
          <i className="fas fa-check-circle" />
        ) : (
          <i className="fas fa-times-circle" />
        );
      }

      return (
        <li
          className={className}
          key={`station-${stationNumber}`}
          onClick={() => {
            if (editMode) {
              if (stationNumber === "group") {
                const newURL = `/admin/circuit_definition/${circuitId}/group`;
                history.push(newURL);
              } else if (selectedStation.station_number !== stationNumber) {
                const newURL = `/admin/circuit_definition/${circuitId}/${stationNumber}`;
                history.push(newURL);
              }
            } else {
              selectStationByNumber({ stationNumber, circuit });
            }
          }}
        >
          {tabIcon}
          {content}
        </li>
      );
    });
  }

  function displaySelectedStation() {
    if (selectedStation === undefined) {
      return null;
    }
    return (
      <StationDetails
        circuit={circuit}
        id={selectedStation.definition_id}
        editMode={editMode}
        onCheckStatusChange={updateSelectedStationStatus}
        isGroupExercises={selectedStation.station_number === 100}
      />
    );
  }

  function displayTimes(prefix) {
    const prefixUppercase =
      prefix.charAt(0).toUpperCase() + prefix.substring(1);
    return (
      <div className={`times-container ${editMode ? "editable" : "static"} `}>
        <h2>{prefixUppercase} times</h2>
        <div className="group">
          <label>rounds</label>
          <input
            value={circuit[`${prefix}_rounds`] || 0}
            onChange={e =>
              setCircuit({
                ...circuit,
                [`${prefix}_rounds`]: parseInt(e.target.value)
              })
            }
          />
        </div>

        <div className="group">
          <label>workout time</label>
          <input
            value={circuit[`${prefix}_workout_time`] || 0}
            onChange={e =>
              setCircuit({
                ...circuit,
                [`${prefix}_workout_time`]: parseInt(e.target.value)
              })
            }
          />
        </div>

        {prefix === "warmup" ? null : (
          <div className="group">
            <label>rest time </label>
            <input
              value={circuit[`${prefix}_rest_time`] || 0}
              onChange={e =>
                setCircuit({
                  ...circuit,
                  [`${prefix}_rest_time`]: parseInt(e.target.value)
                })
              }
            />
          </div>
        )}
        <div className="group">
          <label>switch time</label>
          <input
            value={circuit[`${prefix}_switch_time`] || 0}
            onChange={e =>
              setCircuit({
                ...circuit,
                [`${prefix}_switch_time`]: parseInt(e.target.value)
              })
            }
          />
        </div>
        {prefix === "warmup" ? null : (
          <div className="group">
            <label>group exercise duration </label>
            <input
              value={circuit[`${prefix}_group_time`] || 0}
              onChange={e =>
                setCircuit({
                  ...circuit,
                  [`${prefix}_group_time`]: parseInt(e.target.value)
                })
              }
            />
          </div>
        )}
      </div>
    );
  }

  function displayChecks(prefix) {
    const prefixUppercase =
      prefix.charAt(0).toUpperCase() + prefix.substring(1);

    const checkIcon = circuit[`${prefix}_checks_ok`] ? (
      <i className="fas fa-check-circle" />
    ) : (
      <i className="fas fa-times-circle" />
    );

    return (
      <div className="checks-container">
        <h3>
          {prefixUppercase} Checks {checkIcon}
        </h3>
        <div className="group">
          <label>Total Workout Time</label>
          <p>{totals[`${prefix}_workout_time`] || 0} seconds</p>
        </div>
        {prefix === "warmup" ? null : (
          <div className="group">
            <label>Total Rest Time</label>
            <p>{totals[`${prefix}_rest_time`] || 0} seconds</p>
          </div>
        )}
        <div className="group">
          <label>Switch Time</label>
          <p>{totals[`${prefix}_switch_time`] || 0} seconds</p>
        </div>
        <div className="group">
          <label>Grand Total</label>
          <p>{totals[`${prefix}_total_time`] || 0} seconds</p>
        </div>
        {prefix === "warmup" ? null : (
          <div className="group">
            <label>Group exercise duration</label>
            <p>{circuit[`${prefix}_group_time`] || 0} seconds</p>
          </div>
        )}
        {prefix === "warmup" ? null : (
          <div className="group">
            <label>Switch duration after group exercise</label>
            <p>
              {circuit[`${prefix}_switch_time`] -
                circuit[`${prefix}_group_time`] || 0}{" "}
              seconds
            </p>
          </div>
        )}
      </div>
    );
  }

  function displayDeleteModal() {
    if (!showDeleteModal) {
      return null;
    }

    let useMessage = <p>This circuit is not used anywhere</p>;

    if (circuit.is_used) {
      useMessage = (
        <div>
          <span>The circuit is already used on the following days:</span>
          <ul>
            {circuit.used_in.map(day => (
              <li key={day.date}>{moment(day.date).format("DD MMM YYYY")}</li>
            ))}
          </ul>
        </div>
      );
    }

    return (
      <Modal onClose={() => setShowDeleteModal(false)} className="delete-modal">
        <h3>Are you sure you want to delete this circuit?</h3>
        <div className="use-message">{useMessage}</div>
        <div className="button-container">
          <Button
            label="No, keep it"
            icon={<i className="fas fa-check" />}
            type="secondary"
            onClick={() => setShowDeleteModal(false)}
          />
          <Button
            label="Yes, delete"
            icon={<i className="fas fa-trash" />}
            className="delete"
            type="primary"
            onClick={() => {
              setShowDeleteModal(false);
              deleteCircuit();
            }}
          />
        </div>
      </Modal>
    );
  }

  function displayEditOrSaveButton() {
    if (editMode) {
      let updateCircuitLabel = "Save circuit";
      if (updateState === "pending") {
        updateCircuitLabel = "Saving...";
      } else if (updateState === "success") {
        updateCircuitLabel = "Circuit updated!";
      } else if (updateState === "error") {
        updateCircuitLabel = "Update failed!";
      }

      let canSaveCircuit = true;
      if (!circuit.checks_ok && circuit.is_used) {
        canSaveCircuit = false;
      }

      return (
        <div className="save-delete-buttons">
          <Button
            label={updateCircuitLabel}
            icon={<i className="fas fa-save" />}
            type="primary"
            enabled={canSaveCircuit}
            className="save"
            onClick={updateCircuit}
          />
          <Button
            label="Delete circuit"
            icon={<i className="fas fa-trash" />}
            type="primary"
            className="delete"
            onClick={() => setShowDeleteModal(true)}
          />
        </div>
      );
    } else {
      return (
        <Link to={`/admin/circuit_definition/${circuit.id}`}>
          <Button
            label="Edit circuit"
            icon={<i className="fas fa-edit" />}
            type="secondary"
            className="edit"
          />
        </Link>
      );
    }
  }

  if (circuit === undefined) {
    return <p>Loading...</p>;
  } else if (circuit === null) {
    return <p>There is no circuit with the id {circuitId}</p>;
  }

  let containerClassName = "circuit-container";
  if (editMode) {
    containerClassName += " edit-mode";
  } else {
    containerClassName += " static-mode";
  }

  return (
    <div className={containerClassName}>
      {displayEditOrSaveButton()}
      <div className="details-row">
        <label>Name</label>
        <input onChange={e => setName(e.target.value)} value={name} />
      </div>
      <div className="times-outer-container">
        {displayTimes("warmup")}
        {displayTimes("station")}
      </div>
      <div className="checks-outer-container">
        {displayChecks("warmup")}
        {displayChecks("station")}
      </div>
      <br />
      <br />
      <h2>Stations:</h2>
      <br />
      <div className="stations-container">
        <ul className="stations">{displayStations()}</ul>
      </div>
      {displaySelectedStation()}
      {displayDeleteModal()}
    </div>
  );
}

export default withRouter(CircuitDetails);
