import React from "react";
import cx from "classnames";

import "./Modal.scss";

export default function Modal({ children, onClose, className }) {
  return (
    <div className={cx("modal-container", className)} onClick={onClose}>
      <div className="modal" onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
}
