import React, { useState } from "react";
import moment from "moment";

import Button from "../Button/Button";

import "./Calendar.scss";

export default function Calendar({
  onSelectCircuit,
  onSelectEmptyDay,
  onMonthChange,
  fullDays
}) {
  const [date, setDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState();
  const [hoveredDate, setHoveredDate] = useState();

  const days = getDaysArray(date);
  const weeks = getWeeks(days);

  function displayWeeks(weeks, fullDays) {
    const weekElements = weeks.map((week, weekIndex) => {
      const dayElements = week.map((day, dayIndex) => {
        let content = null;
        let circuit;
        let onClickHandler = () => {};
        if (day) {
          circuit = fullDays[day.date];

          if (circuit) {
            onClickHandler = () => {
              onSelectCircuit(circuit);
              setSelectedDate(day.date);
            };
          } else {
            onClickHandler = () => {
              setSelectedDate();
              onSelectEmptyDay(day.date);
            };
          }

          const duplicateElement =
            !circuit || !circuit.duplicateWith ? null : (
              <p className="hover-info">
                Duplicated with <br />
                {circuit && moment(circuit.duplicateWith).format("DD MMM YYYY")}
              </p>
            );

          content = (
            <div>
              <p>{day.dayNumber}</p>
              <div className={`mark ${circuit ? "full" : "empty"} `}></div>
              {circuit && circuit.duplicateWith && (
                <i className="fas fa-exclamation-triangle duplicated" />
              )}
              {day && circuit && <p className="circuit-name">{circuit.name}</p>}
              {hoveredDate !== day.date ? null : duplicateElement}
            </div>
          );
        }

        let tdClassName = "day";
        if (day && day.date === selectedDate) {
          tdClassName += " selected";
        }
        if (day && day.date) {
          tdClassName += " real";
        }

        if (day && day.date === moment().format("YYYY-MM-DD")) {
          tdClassName += " today";
        }

        return (
          <td
            key={`${weekIndex}-${dayIndex}`}
            className={tdClassName}
            onClick={onClickHandler}
            onMouseEnter={() => setHoveredDate(day && day.date)}
            onMouseLeave={() => setHoveredDate()}
          >
            {content}
          </td>
        );
      });
      return <tr key={weekIndex}>{dayElements}</tr>;
    });
    return (
      <table className="calendar">
        <thead>
          <tr>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
            <th>Saturday</th>
            <th>Sunday</th>
          </tr>
        </thead>

        <tbody>{weekElements}</tbody>
      </table>
    );
  }

  if (!fullDays) {
    return <p>Loading...</p>;
  }

  return (
    <div className="calendar-container">
      <div className="top-bar">
        <Button
          onClick={() => {
            onMonthChange();
            setDate(moment(date).subtract(1, "month"));
          }}
          label="Previous Month"
          icon={<i className="fas fa-chevron-left" />}
          iconPosition="left"
          type="secondary"
        />

        <span> {date.format("MMMM YYYY")}</span>
        <Button
          onClick={() => {
            onMonthChange();
            setDate(moment(date).add(1, "month"));
          }}
          icon={<i className="fas fa-chevron-right" />}
          iconPosition="right"
          label="Next Month"
          type="secondary"
        />
      </div>

      {displayWeeks(weeks, fullDays)}
    </div>
  );
}

function getWeeks(days) {
  return Array(Math.ceil(days.length / 7))
    .fill(null)
    .map((_, weekIndex) => {
      return days.slice(weekIndex * 7, (weekIndex + 1) * 7);
    });
}

function getDaysArray(date) {
  const beginningOfMonth = date.subtract(date.date() - 1, "days");
  const daysInMonth = date.daysInMonth();
  const lastDayInMonth = moment(beginningOfMonth).add(daysInMonth - 1, "days");

  const firstWeekday = beginningOfMonth.isoWeekday();
  const lastWeekday = lastDayInMonth.isoWeekday();

  return [
    ...Array(firstWeekday - 1 || 0),
    ...Array(daysInMonth)
      .fill(null)
      .map((_, i) => ({
        dayNumber: i + 1,
        date: moment(beginningOfMonth)
          .add(i, "days")
          .format("YYYY-MM-DD")
      })),
    ...Array(7 - lastWeekday || 0)
  ];
}
